import { createAction } from '@reduxjs/toolkit'
import { AppState } from '../../appState'

export const setAIAgentReportMessage = createAction<
  AppState['ai']['reportMessage']['message']
>('ai/setAIAgentReportMessage')

export const setAIAgentReportMessageReducer = (
  state: AppState['ai'],
  action: ReturnType<typeof setAIAgentReportMessage>,
): void => {
  state.reportMessage.message = action.payload
}
