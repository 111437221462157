import { createAction } from '@reduxjs/toolkit'
import { AppState } from '../../appState'

export const setAIAgentListCursor = createAction<
  AppState['ai']['popup']['listCursor']
>('ai/setAIAgentListCursor')

export const setAIAgentListCursorReducer = (
  state: AppState['ai'],
  action: ReturnType<typeof setAIAgentListCursor>,
): void => {
  state.popup.listCursor = action.payload
}
