import { ExtensionFactory } from 'joi'
import { lng } from '@ads/front-core/locales'

const PHONE_NUMBER_MIN = 9
const PHONE_NUMBER_MAX = 13

export const create = (): ExtensionFactory => joi => ({
  type: 'phone',
  base: joi.string(),
  messages: {
    'phone.format': lng('errors:phoneNumber'),
    'phone.min': lng('errors:phoneNumberSmaller', { limit: PHONE_NUMBER_MIN }),
    'phone.max': lng('errors:phoneNumberGreater', { limit: PHONE_NUMBER_MAX }),
  },
  validate: (value, { error }) => {
    if (!/^[+]?[\s\-0-9]+$/.test(value)) {
      return { value, errors: error('phone.format') }
    }

    if (value.replaceAll(' ', '').length < PHONE_NUMBER_MIN) {
      return { value, errors: error('phone.min') }
    }

    if (value.replaceAll(' ', '').length > PHONE_NUMBER_MAX) {
      return { value, errors: error('phone.max') }
    }

    if (value) return { value }
  },
})
