import { ExtensionFactory } from 'joi'
import { lng } from '@ads/front-core/locales'
import { Targeting } from '@ads/front-core/api'

export const create = (): ExtensionFactory => joi => ({
  type: 'targetingIAB',
  base: joi.object(),
  messages: {
    'targetingIAB.required': lng('campaign:errorSetContextualTargeting'),
  },
  validate: (value: Targeting['iabCategories'], { error }) => {
    const hasSelectedElement =
      value?.included?.length > 0 || value?.excluded?.length > 0

    if (!value) {
      return { value }
    }

    if (
      !value.attributes?.includeArticleCategories &&
      !value.attributes?.includeDomainCategories &&
      hasSelectedElement
    ) {
      return { value, errors: error('targetingIAB.required') }
    }

    return { value }
  },
})
