import { ExtensionFactory } from 'joi'
import { lng } from '@ads/front-core/locales'

export const create = (): ExtensionFactory => joi => ({
  type: 'inlineNumberArray',
  base: joi.string(),
  messages: {
    'inlineNumberArray.format': lng('errors:inlineNumberArray'),
  },
  validate: (value, { error }) => {
    const isOnlyNumbers = !value
      ?.split(',')
      .every(item => !isNaN(Number(item.trim())))
    if (isOnlyNumbers) {
      return { value, errors: error('inlineNumberArray.format') }
    }

    return { value }
  },
})
