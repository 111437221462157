import { CreateSelector } from '@ads/front-core/interfaces/createSelector'
import { RootState } from '../rootState'

export const getFormSubmittingState: CreateSelector<
  string,
  RootState['app']['formSubmittingState'][string]
> = formKey => state => {
  if (formKey) {
    return state.app.formSubmittingState[formKey] || { isSubmitting: false }
  }
  const submittingFormKey = Object.keys(state.app.formSubmittingState).find(
    formKey => state.app.formSubmittingState[formKey]?.isSubmitting,
  )
  return (
    state.app.formSubmittingState[submittingFormKey] || { isSubmitting: false }
  )
}
