import React, { FC } from 'react'

interface IThumbUpSolid {
  color?: string
}

export const ThumbUpSolid: FC<IThumbUpSolid> = ({ color }) => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.1663 8.33337C19.1663 7.40837 18.4163 6.66671 17.4997 6.66671H12.233L13.033 2.85837C13.0497 2.77504 13.058 2.68337 13.058 2.59171C13.058 2.25004 12.9163 1.93337 12.6913 1.70837L11.808 0.833374L6.32468 6.31671C6.01634 6.62504 5.83301 7.04171 5.83301 7.50004V15.8334C5.83301 16.2754 6.0086 16.6993 6.32116 17.0119C6.63372 17.3244 7.05765 17.5 7.49967 17.5H14.9997C15.6913 17.5 16.283 17.0834 16.533 16.4834L19.0497 10.6084C19.1247 10.4167 19.1663 10.2167 19.1663 10V8.33337ZM0.833008 17.5H4.16634V7.50004H0.833008V17.5Z"
      fill={color || 'currentColor'}
    />
  </svg>
)
