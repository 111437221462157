import { useEffect, useMemo } from 'react'
import {
  Path,
  Resolver,
  SubmitHandler,
  UseFormProps,
  UseFormReturn,
  useForm,
} from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { joiResolver } from '@hookform/resolvers/joi'
import { v4 as uuid } from 'uuid'
import { ADSApiError } from '@ads/front-core/interfaces'
import { setFormSubmittingState } from '@ads/front-core/store/reducers/app/setFormSubmittingStateReducer'
import { setIsFormActive } from '@ads/front-core/store/reducers/app/setIsFormActiveReducer'
import { apiError } from '@ads/front-core/store/sagas/apiErrorSaga'
import { getFormSubmittingState } from '@ads/front-core/store/selectors/getFormSubmittingState'
import { scrollToFirstError } from '@ads/front-core/utils'
import { ObjectSchema } from '@ads/front-core/utils/joi'

interface IUseAdsForm<T> {
  joiSchema?: ObjectSchema<T>
  disableSubmittingOverlay?: boolean
  onSubmit?: SubmitHandler<T>
  onSubmitError?: (error: ADSApiError) => void
}

export type HandleSubmit<T> = {
  isValid: boolean
  errors?: UseFormReturn<T>['formState']['errors']
  res?: unknown
}

export type UseAdsFormReturn<T> = Pick<
  UseFormReturn<T>,
  | 'control'
  | 'formState'
  | 'setValue'
  | 'getValues'
  | 'setError'
  | 'trigger'
  | 'register'
  | 'reset'
  | 'getFieldState'
  | 'clearErrors'
> & {
  isSubmitting?: boolean
  form: UseFormReturn<T>
  handleSubmit: () => Promise<HandleSubmit<T>>
}

export const useAdsForm = <T>({
  joiSchema,
  disableSubmittingOverlay,
  onSubmit,
  onSubmitError,
  ...useFormProps
}: IUseAdsForm<T> & UseFormProps<T>): UseAdsFormReturn<T> => {
  const form = useForm<T>({
    ...useFormProps,
    ...(joiSchema ? { resolver: joiResolver(joiSchema) as Resolver<T> } : {}),
  })

  const {
    control,
    formState,
    handleSubmit: _handleSubmit,
    register,
    reset,
    setValue,
    getValues,
    setError,
    trigger,
    getFieldState,
    clearErrors,
  } = form

  const dispatch = useDispatch()
  const formKey = useMemo(() => uuid(), [])

  const { isSubmitting } = useSelector(getFormSubmittingState(formKey))

  const doSubmit: SubmitHandler<T> = async (
    data,
  ): Promise<{ isValid: boolean; res?: unknown }> => {
    try {
      if (typeof onSubmit === 'function') {
        const res = await onSubmit(data)
        return {
          isValid: true,
          res,
        }
      }
      return { isValid: true }
    } catch (error) {
      switch (error?.status) {
        case 422: {
          if (!Array.isArray(error?.body)) {
            dispatch(apiError({ error }))
            return {
              isValid: false,
            }
          }

          const notExistsKey: string[] = []
          const values = getValues()
          const fields = Object.keys(values)

          const responseErrors: Record<string, string> = error?.body.reduce(
            (acc, item) => ({ ...acc, [item.field]: item.message }),
            {},
          )

          Object.keys(responseErrors).forEach(errorKey => {
            if (fields.includes(errorKey)) {
              setError(errorKey as Path<T>, {
                message: responseErrors[errorKey],
              })
            } else {
              const arrayLastKey = errorKey.match(/\.\d+\..+\.([a-z]+)$/)
              if (arrayLastKey && fields.includes(arrayLastKey[1])) {
                setError(arrayLastKey[1] as Path<T>, {
                  message: responseErrors[errorKey],
                })
              } else {
                notExistsKey.push(`${errorKey}: ${responseErrors[errorKey]}`)
              }
            }
          })

          if (notExistsKey.length) {
            dispatch(
              apiError({
                error,
                label: notExistsKey.join(', '),
              }),
            )
          }
          break
        }
        default:
          if (typeof onSubmitError === 'function') {
            onSubmitError(error)
          } else {
            dispatch(apiError({ error }))
          }
      }
    }
    return {
      isValid: false,
    }
  }

  const handleSubmit = async (): Promise<HandleSubmit<T>> =>
    new Promise(resolve =>
      _handleSubmit(
        async data => {
          dispatch(
            setFormSubmittingState({
              formKey,
              isSubmitting: true,
              withOverlay: !disableSubmittingOverlay,
            }),
          )
          const resDoSubmit = await doSubmit(data)
          dispatch(
            setFormSubmittingState({
              formKey,
              isSubmitting: false,
            }),
          )
          resolve(resDoSubmit as any)
        },
        errors => {
          console.log('Validation error', { values: getValues(), errors })
          dispatch(
            setFormSubmittingState({
              formKey,
              isSubmitting: false,
            }),
          )
          resolve({ isValid: false, errors })
          scrollToFirstError()
        },
      )(),
    )

  useEffect(() => {
    dispatch(setIsFormActive(true))
    return () => {
      dispatch(setIsFormActive(false))
    }
  }, [])

  return {
    form,
    control,
    formState,
    isSubmitting,
    register,
    reset,
    setValue,
    getValues,
    setError,
    handleSubmit,
    trigger,
    getFieldState,
    clearErrors,
  }
}
