import { ExtensionFactory } from 'joi'
import { lng } from '@ads/front-core/locales'

export const create = (): ExtensionFactory => joi => ({
  type: 'utm',
  base: joi.string(),
  messages: {
    'utm.format': lng('errors:stringPatternBase'),
  },
  validate: (value, { error }) => {
    if (!value || /^([A-Za-z0-9_.-]+)$/.test(value)) {
      return {
        value,
      }
    }
    return { value, errors: error('utm.format') }
  },
})
