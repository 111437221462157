import { createAction } from '@reduxjs/toolkit'
import { AppState } from '../../appState'

export const setAIAgentSessionId = createAction<
  AppState['ai']['popup']['sessionId']
>('ai/setAIAgentSessionId')

export const setAIAgentSessionIdReducer = (
  state: AppState['ai'],
  action: ReturnType<typeof setAIAgentSessionId>,
): void => {
  state.popup.sessionId = action.payload
}
