import React, { FC } from 'react'
import { AIIcon } from './interfaces'

export const AIAvatarList: FC<AIIcon> = ({ width, height }) => (
  <svg
    width={width || 28}
    height={height || 28}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="14" cy="14" r="14" fill="#4E97EA" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.7399 12.2435C22.5646 9.50938 19.6125 7.60857 15.9747 7.09877C15.6287 6.67989 15.1057 6.41192 14.5234 6.41192H14.361L14.2907 5.13907C14.7177 5.00288 15.0273 4.60314 15.0273 4.13088C15.0273 3.54673 14.5535 3.073 13.9694 3.073C13.3852 3.073 12.9115 3.54673 12.9115 4.13088C12.9115 4.60314 13.2211 5.00288 13.648 5.13907L13.5777 6.41192H13.4463C12.864 6.41192 12.341 6.67989 11.995 7.09877C8.35715 7.60894 5.40472 9.50938 4.22978 12.2435C3.34748 12.3731 2.66431 13.138 2.66431 14.0549V15.4477C2.66431 16.3131 3.27276 17.0427 4.08292 17.2308C5.31527 20.5542 9.2435 22.5194 13.9097 22.5404C13.8869 24.1953 13.4802 24.8645 13.4802 24.8645C13.4802 24.8645 18.2867 23.3344 21.5483 20.3153C21.5384 20.3194 21.5281 20.3231 21.5181 20.3271C22.6099 19.4996 23.4325 18.458 23.8879 17.2308C24.698 17.0423 25.3065 16.3127 25.3065 15.4477V14.0549C25.3065 13.138 24.6233 12.3731 23.741 12.2435H23.7399ZM13.9845 20.9113C9.57441 20.9113 5.99918 18.7925 5.99918 15.3325C5.99918 11.8725 9.57441 9.47919 13.9845 9.47919C18.3945 9.47919 21.9698 11.8725 21.9698 15.3325C21.9698 18.7925 18.3945 20.9113 13.9845 20.9113ZM10.2841 16.3323C11.2269 16.3323 11.9913 15.5679 11.9913 14.6251C11.9913 13.6822 11.2269 12.9179 10.2841 12.9179C9.34124 12.9179 8.5769 13.6822 8.5769 14.6251C8.5769 15.5679 9.34124 16.3323 10.2841 16.3323ZM19.392 14.6251C19.392 15.5679 18.6277 16.3323 17.6849 16.3323C16.742 16.3323 15.9777 15.5679 15.9777 14.6251C15.9777 13.6822 16.742 12.9179 17.6849 12.9179C18.6277 12.9179 19.392 13.6822 19.392 14.6251Z"
      fill="white"
    />
  </svg>
)
