import React, { FC, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { Flex, IconButton, Text } from '@ads/front-ds'
import { ExternalTextLink } from '@ads/front-core/atoms'
import { Trans } from '@ads/front-core/locales'
import { AIRulesLSKey, AI_RULES_URL } from './constants'

export const AIPopupRules: FC = () => {
  const [isVisible, setIsVisible] = useState<boolean>(null)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsVisible(!window.localStorage.getItem(AIRulesLSKey))
    }
  }, [])

  const onClickClose = () => {
    window.localStorage.setItem(AIRulesLSKey, '1')
    setIsVisible(false)
  }

  if (!isVisible) {
    return null
  }

  return (
    <Wrapper>
      <Text fontSize="s">
        <Trans
          i18nKey="ai:chat.rulesBanner"
          components={{
            textLink: <ExternalTextLink href={AI_RULES_URL} target="_blank" />,
          }}
        />
      </Text>
      <IconButtonStyled name="20-close" onClick={onClickClose} />
    </Wrapper>
  )
}

const Wrapper = styled(Flex)`
  ${p => css`
    border-top: ${p.theme.borders.thinGrey20};
    background-color: ${p.theme.colors.primary05};
    padding: ${p.theme.space.xs}px 20px;
    flex-flow: row;
    gap: ${p.theme.space.s}px;
  `}
`

const IconButtonStyled = styled(IconButton)`
  ${p => css`
    color: ${p.theme.colors.primary100};
  `}
`
