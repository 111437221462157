import React, { FC } from 'react'
import styled, { css } from 'styled-components'
import { Box, Text } from '@ads/front-ds'
import { getLocateDate } from '@ads/front-core/utils'

interface IDateSeparator {
  date: Date
}

export const DateSeparator: FC<IDateSeparator> = ({ date }) => {
  return (
    <Wrapper>
      <Date>{getLocateDate(date)}</Date>
    </Wrapper>
  )
}

const Wrapper = styled(Box)`
  ${p => css`
    border-bottom: ${p.theme.borders.thinGrey20};
    position: relative;
    margin-top: ${p.theme.space.m}px;
    margin-bottom: ${p.theme.space.m}px;
  `}
`

const Date = styled(Text)`
  ${p => css`
    position: absolute;
    background-color: ${p.theme.colors.inverted100};
    padding: 0 ${p.theme.space.xs}px;
    color: ${p.theme.colors.greyDisabled};
    top: -10px;
    transform: translate(-50%, 0);
    left: 50%;
  `}
`
