import { ExtensionFactory, StringSchema } from 'joi'
import { lng } from '@ads/front-core/locales'

export interface UrlSchema extends StringSchema {
  validDomain: (validDomain: string) => this
}

export const URL_MAX_LENGTH = 2048

export const validateURL = (value: string): boolean => {
  try {
    const url = new URL(value)
    const valid =
      new RegExp(/^([a-z0-9-]+\.)+[a-z0-9-]{2,}$/).test(url.hostname) &&
      ['http:', 'https:'].includes(url.protocol)
    return valid
  } catch (e) {
    return false
  }
}

export const create = (): ExtensionFactory => joi => ({
  type: 'url',
  base: joi.string(),
  messages: {
    'url.max': lng('errors:textMaxSigns', { length: URL_MAX_LENGTH }),
    'url.format': lng('errors:urlFormat'),
    'url.validDomain': lng('errors:targetUrlWrongDomainError'),
  },
  validate: (value, { error }) => {
    if (value.length > URL_MAX_LENGTH) {
      return { value, errors: error('url.max') }
    }

    const result = validateURL(value)
    if (result) {
      return { value }
    }

    if (
      !value ||
      /^https?:\/\//.test(value) ||
      !/[a-z0-9]+\.[a-z0-9]+/.test(value)
    ) {
      return { value, errors: error('url.format') }
    }

    const normalizedUrl = `https://${value}`
    if (normalizedUrl.length > URL_MAX_LENGTH) {
      return { value, errors: error('url.max') }
    }

    const normalizedResult = validateURL(normalizedUrl)
    if (!normalizedResult) {
      return { value, errors: error('url.format') }
    }

    return { value }
  },
  rules: {
    validDomain: {
      method(validDomain) {
        return this.$_addRule({ name: 'validDomain', args: { validDomain } })
      },
      args: [
        {
          name: 'validDomain',
          ref: true,
          assert: joi.string(),
        },
      ],
      validate: (value, { error }, { validDomain }) => {
        try {
          const result = validateURL(value)

          if (!result) {
            return error('url.format')
          }

          const domainUrl = new URL(value)
          const validDomainUrl = new URL(validDomain)
          if (domainUrl.host !== validDomainUrl.host) {
            return error('url.validDomain', { domain: validDomain })
          }
          return value
        } catch (error) {
          return error('any.invalid')
        }
      },
    },
  },
})
