import { createAction } from '@reduxjs/toolkit'
import { AppState } from '../../appState'

export const setAIAgentAbuseReport = createAction<
  AppState['ai']['popup']['isAbuseReport']
>('ai/setAIAgentAbuseReport')

export const setAIAgentAbuseReportReducer = (
  state: AppState['ai'],
  action: ReturnType<typeof setAIAgentAbuseReport>,
): void => {
  state.popup.isAbuseReport = action.payload
}
