import React, { FC } from 'react'

export const SadAvatar: FC = () => (
  <svg
    width="140"
    height="139"
    viewBox="0 0 140 139"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="15"
      y="34"
      width="110"
      height="100"
      rx="15"
      stroke="#0050AB"
      strokeWidth="10"
    />
    <ellipse cx="10" cy="83.5" rx="10" ry="13.5" fill="#0050AB" />
    <ellipse cx="130" cy="83.5" rx="10" ry="13.5" fill="#0050AB" />
    <line x1="71" y1="29" x2="71" y2="8" stroke="#0050AB" strokeWidth="10" />
    <rect x="59" width="24" height="10" rx="5" fill="#0050AB" />
    <path
      d="M89 119L88.5794 118.513C78.88 107.282 61.3969 107.515 52 119V119"
      stroke="#0050AB"
      strokeWidth="6"
      strokeLinecap="round"
    />
    <circle cx="47" cy="70" r="15" stroke="#0050AB" strokeWidth="6" />
    <circle cx="94" cy="70" r="15" stroke="#0050AB" strokeWidth="6" />
    <circle cx="47" cy="70" r="6" fill="#0050AB" />
    <circle cx="94" cy="70" r="6" fill="#0050AB" />
  </svg>
)
