import React, { FC } from 'react'
import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  UseControllerProps,
} from 'react-hook-form'
import styled, { css } from 'styled-components'
import {
  Box,
  ITextarea as IITextareaDS,
  Text,
  Textarea as TextareaDS,
} from '@ads/front-ds'
import FormGroup, { IFormGroup } from '../FormGroup'
import { lng } from '@ads/front-core/locales'
import { UseAdsFormReturn } from '@ads/front-core/hooks/useAdsForm'

export interface ITextArea
  extends Partial<Pick<UseAdsFormReturn<any>, 'control' | 'trigger'>>,
    IFormGroup,
    Pick<IITextareaDS, 'rows'> {
  name: string
  label?: string
  caption?: string
  value?: string
  defaultValue?: UseControllerProps['defaultValue']
  placeholder?: string
  disabled?: boolean
  readOnly?: boolean
  maxLength?: number
}

const TextArea: FC<ITextArea> = ({
  name,
  control,
  defaultValue,
  placeholder,
  disabled,
  error,
  readOnly,
  rows,
  maxLength,
  trigger,
  ...rest
}) => {
  const onInputChange = (
    field?: ControllerRenderProps,
    fieldState?: ControllerFieldState,
    e?: React.FormEvent<HTMLInputElement>,
  ) => {
    if (typeof field?.onChange === 'function') {
      field.onChange(e)
      if (fieldState?.isTouched && typeof trigger === 'function') {
        trigger(field?.name)
      }
    }
  }

  const onInputBlur = (field?: ControllerRenderProps) => {
    if (typeof field?.onBlur === 'function') {
      field.onBlur()
      if (typeof trigger === 'function') {
        trigger(field?.name)
      }
    }
  }

  return (
    <FormGroup error={error} disabled={disabled} {...rest}>
      <Controller
        render={({ field, fieldState }) => (
          <Box styleCss={{ position: 'relative' }}>
            <TextareaStyled
              data-testid={`textarea-${field.name}`}
              {...field}
              disabled={disabled}
              placeholder={placeholder}
              readOnly={readOnly}
              rows={rows}
              isError={!!error}
              maxLength={maxLength}
              withCounter={!!maxLength}
              onChange={e => onInputChange(field, fieldState, e)}
              onBlur={() => onInputBlur(field)}
            />
            {maxLength > 0 && (
              <MaxLengthCounter>
                {lng('common:signs')} {field.value?.length || 0}/{maxLength}
              </MaxLengthCounter>
            )}
          </Box>
        )}
        name={name}
        control={control}
        defaultValue={defaultValue}
      />
    </FormGroup>
  )
}

const TextareaStyled = styled(TextareaDS)<
  IITextareaDS & { readOnly?: boolean; withCounter: boolean }
>`
  ${p => css`
    ${p.readOnly &&
    css`
      textarea {
        cursor: not-allowed;
      }
    `}

    ${p.withCounter &&
    css`
      align-content: center;
      padding-right: 106px;
    `}
  `}
`

const MaxLengthCounter = styled(Text)`
  ${p => css`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: ${p.theme.space.s}px;
    color: ${p.theme.colors.grey50};
  `}
`

export default TextArea
