import { all } from 'redux-saga/effects'
import rootSagas from '@ads/front-core/store/sagas'
import { sagaAuthorization } from './authorizationSaga'
import { sagaCacheDictionaries } from './cacheDictionaries'
import { sagaFetchBalance } from './fetchBalanceSaga'
import { sagaFetchClientData } from './fetchClientDataSaga'
import { sagaFetchClientShopsData } from './fetchClientShopsDataSaga'
import { sagaFetchFeedsData } from './fetchFeedsDataSaga'
import { sagaFetchShopData } from './fetchShopDataSaga'
import { sagaFetchUserData } from './fetchUserDataSaga'
import { sagaLogout } from './logoutSaga'
import { sagaSetCurrentShop } from './setCurrentShopSaga'
import { sagaSetAIAgentButton } from './setAIAgentButton'

function* rootSaga(): Generator {
  yield all([
    ...rootSagas.map(rs => rs()),
    sagaAuthorization(),
    sagaFetchUserData(),
    sagaFetchBalance(),
    sagaSetCurrentShop(),
    sagaLogout(),
    sagaFetchClientData(),
    sagaFetchShopData(),
    sagaFetchFeedsData(),
    sagaCacheDictionaries(),
    sagaFetchClientShopsData(),
    sagaSetAIAgentButton(),
  ])
}

export default rootSaga
