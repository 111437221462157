import { useEffect, useRef, RefObject } from 'react'
import { useData } from '../useData'
import { AIMessage, AISendPrompt } from '../interfaces'

export const useScrollList = (): {
  scrollRef: RefObject<HTMLDivElement>
  messages: AIMessage[]
  isTyping: boolean
  sendPrompt: AISendPrompt
} => {
  const scrollRef = useRef<HTMLDivElement>()
  const scrollToElementRef = useRef<string>()

  const {
    messages,
    listCursor,
    isTyping,
    fetchList,
    sendPrompt,
    checkLimitExceeded,
  } = useData()

  const scrollEffect = JSON.stringify(
    messages?.map(m => `${m.response}${m.prompt}${m.questions?.length}`),
  )

  useEffect(() => {
    if (scrollRef?.current && !scrollToElementRef.current) {
      scrollRef.current.scrollTo({
        left: 0,
        top: scrollRef.current.scrollHeight + 100,
        behavior: 'smooth',
      })
    }
  }, [scrollEffect])

  useEffect(() => {
    fetchList({ cursorId: null })
    checkLimitExceeded()
  }, [])

  useEffect(() => {
    let disableScrollTimer = null

    const onScroll = async () => {
      const scrollTop = scrollRef.current?.scrollTop
      if (scrollTop === 0 && messages?.length > 0 && !listCursor?.isLoading) {
        const oldestMessage = messages[0]
        scrollToElementRef.current = oldestMessage.id
        await fetchList({
          cursorId: oldestMessage.id,
          sessionId: oldestMessage.sessionId,
        })

        disableScrollTimer = setTimeout(() => {
          const oldestElement = scrollRef.current.querySelector(
            `[data-id="${scrollToElementRef.current}"]`,
          )
          const oldestElementTop = oldestElement?.getBoundingClientRect()?.top
          if (!scrollRef.current || !oldestElementTop) {
            return
          }
          scrollRef.current.scrollTo({
            left: 0,
            top: scrollRef.current.scrollTop + oldestElementTop - 53,
            behavior: 'auto',
          })
          scrollToElementRef.current = null
        }, 50)
      }
    }

    if (scrollRef?.current) {
      scrollRef.current.addEventListener('scroll', onScroll)
    }

    return () => {
      scrollRef.current?.removeEventListener('scroll', onScroll)

      if (disableScrollTimer) {
        clearTimeout(disableScrollTimer)
      }
    }
  }, [scrollRef?.current, messages?.length, listCursor?.isLoading])

  return {
    scrollRef,
    messages,
    isTyping,
    sendPrompt,
  }
}
