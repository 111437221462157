import { RawDraftContentState } from 'draft-js'
import { ExtensionFactory } from 'joi'
import { lng } from '@ads/front-core/locales'

export const create = (): ExtensionFactory => joi => ({
  type: 'draftJS',
  base: joi.object(),
  messages: {
    'draftJS.empty': lng('errors:fieldCanNotBeEmpty'),
  },
  validate: (value: RawDraftContentState, { error }) => {
    if (Array.isArray((value || {})?.blocks)) {
      return { value }
    }
    return { value, errors: error('draftJS.empty') }
  },
})
