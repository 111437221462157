import { createAction } from '@reduxjs/toolkit'
import { AppState } from '../../appState'

type SetAIAgentList = {
  direction: 'new' | 'old'
  items: AppState['ai']['popup']['list']
}

export const setAIAgentList = createAction<SetAIAgentList>('ai/setAIAgentList')

export const setAIAgentListReducer = (
  state: AppState['ai'],
  action: ReturnType<typeof setAIAgentList>,
): void => {
  const { direction, items } = action.payload

  const itemsFiltered = items.filter(
    item => !state.popup.list.find(listItem => listItem.id === item.id),
  )

  if (direction === 'new') {
    state.popup.list.push(...itemsFiltered)
    return
  }
  state.popup.list = new Array(...itemsFiltered).concat(state.popup.list)
}
