import { ExtensionFactory } from 'joi'
import { create as createAdsId } from './adsId'
import { create as createArrayField } from './arrayField'
import { create as createColor } from './color'
import { create as createDomain } from './domain'
import { create as createDraftJS } from './draftJS'
import { create as createHTMLField } from './html'
import { create as createInlineNumberArray } from './inlineNumberArray'
import { create as createNip } from './nip'
import { create as createNumberField } from './number'
import { create as createPhoneField } from './phone'
import { create as createPostalCode } from './postalCode'
import { create as createTextField } from './textField'
import { create as createUrl } from './url'
import { create as createUTMField } from './utm'
import { create as createTargetingIAB } from './targetingIAB'

export class TypesFactory {
  public static createTextField(): ExtensionFactory {
    return createTextField()
  }

  public static createAdsId(): ExtensionFactory {
    return createAdsId()
  }

  public static createUrl(): ExtensionFactory {
    return createUrl()
  }

  public static createDraftJS(): ExtensionFactory {
    return createDraftJS()
  }

  public static createColor(): ExtensionFactory {
    return createColor()
  }

  public static createDomain(): ExtensionFactory {
    return createDomain()
  }

  public static createNip(): ExtensionFactory {
    return createNip()
  }

  public static createPostalCode(): ExtensionFactory {
    return createPostalCode()
  }

  public static createArrayField(): ExtensionFactory {
    return createArrayField()
  }

  public static createNumberField(): ExtensionFactory {
    return createNumberField()
  }

  public static createInlineNumberArray(): ExtensionFactory {
    return createInlineNumberArray()
  }

  public static createPhoneField(): ExtensionFactory {
    return createPhoneField()
  }

  public static createHTMLField(): ExtensionFactory {
    return createHTMLField()
  }

  public static createUTMField(): ExtensionFactory {
    return createUTMField()
  }

  public static createTargetingIAB(): ExtensionFactory {
    return createTargetingIAB()
  }
}
