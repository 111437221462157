import React, { FC } from 'react'

interface IThumbUp {
  color?: string
}

export const ThumbUp: FC<IThumbUp> = ({ color }) => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.16683 7.50004V17.5H0.833496V7.50004H4.16683ZM7.50016 17.5C7.05814 17.5 6.63421 17.3244 6.32165 17.0119C6.00909 16.6993 5.8335 16.2754 5.8335 15.8334V7.50004C5.8335 7.04171 6.01683 6.62504 6.32516 6.32504L11.8085 0.833374L12.6918 1.71671C12.9168 1.94171 13.0585 2.25004 13.0585 2.59171L13.0335 2.85837L12.2418 6.66671H17.5002C18.4252 6.66671 19.1668 7.41671 19.1668 8.33337V10C19.1668 10.2167 19.1252 10.4167 19.0502 10.6084L16.5335 16.4834C16.2835 17.0834 15.6918 17.5 15.0002 17.5H7.50016ZM7.50016 15.8334H15.0252L17.5002 10V8.33337H10.1752L11.1168 3.90004L7.50016 7.52504V15.8334Z"
      fill={color || 'currentColor'}
    />
  </svg>
)
