import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'
import { Flex, Text } from '@ads/front-ds'
import { lng } from '@ads/front-core/locales'
import { setAIAgentReportMessage } from 'store/reducers/ai/setAIAgentReportMessage'
import { setAIAgentReportMessageSending } from 'store/reducers/ai/setAIAgentReportMessageSending'
import { AIIconWave } from 'components/AIAgent/Icons/AIIconWave'
import { ButtonCTA } from './styles'

export const Success: FC = () => {
  const dispatch = useDispatch()

  const handleOnClose = () => {
    dispatch(setAIAgentReportMessageSending(false))
    dispatch(setAIAgentReportMessage(null))
  }

  return (
    <>
      <Wrapper>
        <AIIconWave width={355} height={400} />
        <Text textAlign="center">
          {lng('ai:chat.reportForm.successMessage')}
        </Text>
      </Wrapper>
      <Buttons>
        <ButtonCTA variant="cta" onClick={handleOnClose}>
          {lng('common:close')}
        </ButtonCTA>
      </Buttons>
    </>
  )
}

const Wrapper = styled(Flex)`
  ${p => css`
    flex: 1;
    flex-flow: column;
    gap: ${p.theme.space.l}px;
    align-items: center;
    justify-content: center;
    padding: ${p.theme.space.xl}px;
  `}
`

const Buttons = styled(Flex)`
  ${p => css`
    border-top: ${p.theme.borders.thinGrey20};
    background-color: ${p.theme.colors.grey0};
    padding: ${p.theme.space.s}px;
    flex-flow: column;
    gap: ${p.theme.space.xs}px;
  `}
`
