import React, { FC } from 'react'
import { FieldError } from 'react-hook-form'
import styled, { css } from 'styled-components'
import {
  FormGroup as FormGroupDS,
  IFormGroup as IFormGroupDS,
  IText,
} from '@ads/front-ds'
import { handleError } from '@ads/front-core/utils/form'

export interface IFormGroup extends Omit<IFormGroupDS, 'error'> {
  labelFontWeight?: IText['fontWeight']
  error?: FieldError | FieldError[]
}

const FormGroup: FC<IFormGroup> = ({
  labelFontWeight,
  error,
  disabled,
  ...rest
}) => (
  <FormGroupStyled
    error={handleError(error, 'Pole wymagane')}
    formGroupDisabled={disabled}
    labelFontWeight={labelFontWeight}
    {...rest}
  />
)

const FormGroupStyled = styled(FormGroupDS)<{
  labelFontWeight?: IText['fontWeight']
  formGroupDisabled?: boolean
}>`
  ${p =>
    p.labelFontWeight &&
    css`
      label {
        font-weight: ${p.theme.fontWeights[p.labelFontWeight]};
      }
    `}
  ${p =>
    p.formGroupDisabled &&
    css`
      label {
        color: ${p.theme.colors.greyDisabled};
      }
    `}
  > div > span {
    white-space: pre-line;
  }
`

export default FormGroup
