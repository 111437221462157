import { ExtensionFactory } from 'joi'
import { lng } from '@ads/front-core/locales'

export const create = (): ExtensionFactory => joi => ({
  type: 'postalCode',
  base: joi.string(),
  messages: {
    'postalCode.format': lng('errors:postalCode'),
  },
  validate: (value, { error }) => {
    if (/^\d{2}-\d{3}$/.test(value)) {
      return {
        value,
      }
    }
    return { value, errors: error('postalCode.format') }
  },
})
