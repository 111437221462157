import { createAction } from '@reduxjs/toolkit'
import { put, takeEvery } from 'redux-saga/effects'
import { setAIAgentButtonState } from 'store/reducers/ai/setAIAgentButtonState'
import {
  AIAgentButtonState,
  AIAgentLSKey,
} from 'components/AIAgent/AIAgentButton'

export function* sagaSetAIAgentButton(): Generator {
  yield takeEvery(setAIAgentButton.type, setAIAgentButtonSaga)
}

export const setAIAgentButton = createAction('saga/setAIAgentButton')

export function* setAIAgentButtonSaga(): Generator {
  const buttonState =
    typeof window !== 'undefined'
      ? window.localStorage.getItem(AIAgentLSKey)
      : null
  yield put(
    setAIAgentButtonState(
      buttonState ? AIAgentButtonState.COILED : AIAgentButtonState.EXPANDED,
    ),
  )
}
