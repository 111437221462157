import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { format } from 'date-fns'
import styled, { css } from 'styled-components'
import { marked } from 'marked'
import { Flex, Text } from '@ads/front-ds'
import { copyToClipboard, getScrollbarStyles } from '@ads/front-core/utils'
import { AIMessage, AISendPrompt } from 'components/AIAgent/AIPopup/interfaces'
import { AIAvatarList } from 'components/AIAgent/Icons/AIAvatarList'
import { Controls } from './Controls'
import { Question } from './Question'

interface IMessage {
  variant: 'ai' | 'user'
  message: AIMessage
  sendPrompt: AISendPrompt
}

export const Message: FC<IMessage> = ({ variant, message, sendPrompt }) => {
  const dispatch = useDispatch()

  if (variant === 'ai' && !message.response) {
    return null
  }

  const messageHTML = marked.parse(
    variant === 'ai' ? message.response : message.prompt,
    {
      async: false,
    },
  )

  const handleOnClick = (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = e?.target as EventTarget & HTMLDivElement
    if (
      target.nodeName === 'PRE' &&
      target.childNodes?.length &&
      target.childNodes.item(0).nodeName === 'CODE'
    ) {
      copyToClipboard(target.innerText, dispatch)
    }
  }

  return (
    <Wrapper variant={variant} data-id={message.id}>
      <Top>
        {variant === 'ai' && <AIAvatarList />}
        <Text fontSize="s" color="greyDisabled">
          {format(message.date, 'HH:mm')}
        </Text>
      </Top>
      <MessageWrapper variant={variant}>
        <MessageContent variant={variant}>
          <div
            dangerouslySetInnerHTML={{ __html: messageHTML }}
            onClick={handleOnClick}
          />
          {variant === 'ai' &&
            message.questions?.length > 0 &&
            message.questions.map((question, i) => (
              <Question
                key={`question-${i}`}
                question={question}
                sendPrompt={sendPrompt}
              />
            ))}
        </MessageContent>
        {variant === 'ai' && <Controls message={message} />}
      </MessageWrapper>
    </Wrapper>
  )
}

const Wrapper = styled(Flex)<{ variant: IMessage['variant'] }>`
  ${p => css`
    padding: 0 ${p.theme.space.xxs}px ${p.theme.space.xs}px
      ${p.theme.space.xxs}px;
    flex-flow: column;
    gap: ${p.theme.space.xxxs}px;
    flex: 1;
    align-items: ${p.variant === 'user' ? 'flex-end' : 'flex-start'};
  `}
`

const Top = styled(Flex)`
  ${p => css`
    gap: ${p.theme.space.xxs}px;
    align-items: center;
  `}
`

const MessageWrapper = styled(Flex)<{ variant: IMessage['variant'] }>`
  ${p => css`
    justify-content: space-between;
    ${p.variant === 'ai' &&
    css`
      width: 100%;
    `}
  `}
`
export const MessageContent = styled(Flex)<{ variant: IMessage['variant'] }>`
  ${p => css`
    flex-flow: column;
    gap: ${p.theme.space.xs}px;
    max-width: 410px;
    padding: ${p.theme.space.s}px;
    background-color: ${p.variant === 'ai' ? p.theme.colors.grey0 : '#CCE1F9'};
    border-radius: ${p.variant === 'ai'
      ? '0 20px 20px 20px'
      : '20px 0 20px 20px'};

    color: ${p.theme.colors.grey100};

    a {
      color: ${p.theme.colors.primary50};
    }

    pre {
      position: relative;
      max-width: 390px;

      &:before {
        color: ${p.theme.colors.primary50};
        cursor: pointer;
        position: absolute;
        top: ${p.theme.space.xxs}px;
        right: ${p.theme.space.xxs}px;
        width: 20px;
        height: 20px;
        display: block;
        content: url("data:image/svg+xml, %3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' color='red' aria-label='Copy' %3E%3Cpath fill='%230050AB' fill-rule='evenodd' d='M16,5 C17.654,5 19,6.346 19,8 L19,8 L19,16 C19,17.654 17.654,19 16,19 L16,19 L8,19 C6.346,19 5,17.654 5,16 L5,16 L5,8 C5,6.346 6.346,5 8,5 L8,5 Z M16,7 L8,7 C7.449,7 7,7.449 7,8 L7,8 L7,16 C7,16.551 7.449,17 8,17 L8,17 L16,17 C16.552,17 17,16.551 17,16 L17,16 L17,8 C17,7.449 16.552,7 16,7 L16,7 Z M12,1 C13.302,1 14.401,1.838 14.815,3 L14.815,3 L4,3 C3.449,3 3,3.449 3,4 L3,4 L3,14.815 C1.838,14.401 1,13.302 1,12 L1,12 L1,4 C1,2.346 2.346,1 4,1 L4,1 Z' %3E%3C/path%3E%3C/svg%3E");
        z-index: 1;
      }

      code {
        background: #ececec;
        display: block;
        padding: ${p.theme.space.xxs}px;
        width: 100%;
        overflow-x: auto;
        font-size: ${p.theme.fontSizes.l}px;
        ${getScrollbarStyles()}
      }
    }
  `}
`
