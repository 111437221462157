import { createAction } from '@reduxjs/toolkit'
import { AppState } from '../../appState'

export const setAIAgentButtonAlign = createAction<
  AppState['ai']['button']['align']
>('ai/setAIAgentButtonAlign')

export const setAIAgentButtonAlignReducer = (
  state: AppState['ai'],
  action: ReturnType<typeof setAIAgentButtonAlign>,
): void => {
  state.button.align = action.payload
}
