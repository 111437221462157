import { createReducer } from '@reduxjs/toolkit'
import { preloadedState } from '../../appState'
import {
  setAIAgentButtonAlign,
  setAIAgentButtonAlignReducer,
} from './setAIAgentButtonAlign'
import {
  setAIAgentButtonState,
  setAIAgentButtonStateReducer,
} from './setAIAgentButtonState'
import {
  setAIAgentPopupVisible,
  setAIAgentPopupVisibleReducer,
} from './setAIAgentPopupVisible'
import {
  setAIAgentClearSession,
  setAIAgentClearSessionReducer,
} from './setAIAgentClearSession'
import { setAIAgentList, setAIAgentListReducer } from './setAIAgentList'
import {
  setAIAgentListResponse,
  setAIAgentListResponseReducer,
} from './setAIAgentListResponse'
import { setAIAgentTyping, setAIAgentTypingReducer } from './setAIAgentTyping'
import {
  setAIAgentPopupOffset,
  setAIAgentPopupOffsetReducer,
} from './setAIAgentPopupOffset'
import {
  setAIAgentListRate,
  setAIAgentListRateReducer,
} from './setAIAgentListRate'
import {
  setAIAgentReportMessage,
  setAIAgentReportMessageReducer,
} from './setAIAgentReportMessage'
import {
  setAIAgentReportMessageSending,
  setAIAgentReportMessageSendingReducer,
} from './setAIAgentReportMessageSending'
import {
  addAIAgentListQuestion,
  addAIAgentListQuestionReducer,
} from './addAIAgentListQuestion'
import {
  setAIAgentAbuseReport,
  setAIAgentAbuseReportReducer,
} from './setAIAgentAbuseReport'
import {
  setAIAgentLimitExceeded,
  setAIAgentLimitExceededReducer,
} from './setAIAgentLimitExceeded'
import {
  setAIAgentListCursor,
  setAIAgentListCursorReducer,
} from './setAIAgentListCursor'
import {
  setAIAgentSessionId,
  setAIAgentSessionIdReducer,
} from './setAIAgentSessionId'

const aiReducers = createReducer(preloadedState.ai, builder => {
  builder.addCase(setAIAgentButtonAlign.type, setAIAgentButtonAlignReducer)
  builder.addCase(setAIAgentButtonState.type, setAIAgentButtonStateReducer)
  builder.addCase(setAIAgentPopupVisible.type, setAIAgentPopupVisibleReducer)
  builder.addCase(setAIAgentClearSession.type, setAIAgentClearSessionReducer)
  builder.addCase(setAIAgentList.type, setAIAgentListReducer)
  builder.addCase(setAIAgentListResponse.type, setAIAgentListResponseReducer)
  builder.addCase(setAIAgentTyping.type, setAIAgentTypingReducer)
  builder.addCase(setAIAgentPopupOffset.type, setAIAgentPopupOffsetReducer)
  builder.addCase(setAIAgentListRate.type, setAIAgentListRateReducer)
  builder.addCase(setAIAgentReportMessage.type, setAIAgentReportMessageReducer)
  builder.addCase(
    setAIAgentReportMessageSending.type,
    setAIAgentReportMessageSendingReducer,
  )
  builder.addCase(addAIAgentListQuestion.type, addAIAgentListQuestionReducer)
  builder.addCase(setAIAgentAbuseReport.type, setAIAgentAbuseReportReducer)
  builder.addCase(setAIAgentLimitExceeded.type, setAIAgentLimitExceededReducer)
  builder.addCase(setAIAgentListCursor.type, setAIAgentListCursorReducer)
  builder.addCase(setAIAgentSessionId.type, setAIAgentSessionIdReducer)
})

export default aiReducers
