import { ExtensionFactory } from 'joi'
import { lng } from '@ads/front-core/locales'

export const URL_MAX_LENGTH = 2048

export const create = (): ExtensionFactory => joi => ({
  type: 'domain',
  base: joi.string(),
  messages: {
    'domain.max': lng('errors:textMaxSigns', {
      length: URL_MAX_LENGTH,
    }),
    'domain.format': lng('errors:domainFormat'),
  },
  validate: (value, { error }) => {
    if (value.length > URL_MAX_LENGTH) {
      return { value, errors: error('domain.max') }
    }
    if (!/^(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}$/.test(value)) {
      return { value, errors: error('domain.format') }
    }
    return { value }
  },
})
