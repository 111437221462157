import { RefObject, useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { IDropdown } from '@ads/front-ds'
import { useWindowScroll } from '@ads/front-core/hooks/useWindowScroll'

const useDropdown = ({
  menuRef,
  initDirection,
}: {
  menuRef?: RefObject<HTMLDivElement>
  initDirection?: IDropdown['direction']
}): {
  isOpen: boolean
  direction: IDropdown['direction']
  menuIsVisible: boolean
  handleToggleDropdown: () => void
  handleOpenDropdown: () => void
  handleCloseDropdown: () => void
} => {
  const [isOpen, setOpen] = useState(false)
  const [menuIsVisible, setVisible] = useState(false)
  const [bounceCount, setBounceCount] = useState(0)
  const [direction, setDirection] = useState<IDropdown['direction']>(
    initDirection || 'down',
  )

  const windowScroll = useWindowScroll()

  const setVisibleCallback = useDebouncedCallback(setVisible, 350)

  const handleToggleDropdown = () => {
    setVisible(false)
    if (isOpen) {
      setOpen(false)
    } else {
      handleOpenDropdown()
    }
  }

  const handleOpenDropdown = () => {
    if (initDirection) {
      setDirection(initDirection)
    }
    setVisible(false)
    setOpen(true)
  }

  const handleCloseDropdown = useDebouncedCallback(() => {
    setVisible(false)
    setOpen(false)
  }, 100)

  const setDirectionDebounced = useDebouncedCallback(setDirection, 350)

  useEffect(() => {
    const menuBounding = menuRef?.current?.getBoundingClientRect()
    if (!isOpen || menuBounding === undefined || !menuRef?.current) {
      setBounceCount(0)
      return
    }

    const menuY = menuBounding.top || 0
    const menuBottom = menuY + menuRef.current.offsetHeight

    if (direction === 'down' && menuBottom > window.innerHeight) {
      if (bounceCount < 2) {
        setDirectionDebounced('up')
        setBounceCount(b => b + 1)
      }
    } else if (
      direction === 'up' &&
      window.innerHeight > menuBottom + menuRef.current.offsetHeight + 100
    ) {
      if (bounceCount < 2) {
        setDirectionDebounced('down')
        setBounceCount(b => b + 1)
      }
    }
    setVisibleCallback(true)
  }, [windowScroll, menuRef, direction, isOpen, setVisibleCallback])

  return {
    isOpen,
    direction,
    menuIsVisible,
    handleToggleDropdown,
    handleOpenDropdown,
    handleCloseDropdown,
  }
}

export default useDropdown
