import { createAction } from '@reduxjs/toolkit'
import { AppState } from '../../appState'

type SetAIAgentListRate = Pick<
  AppState['ai']['popup']['list'][number],
  'id' | 'rating'
>

export const setAIAgentListRate = createAction<SetAIAgentListRate>(
  'ai/setAIAgentListRate',
)

export const setAIAgentListRateReducer = (
  state: AppState['ai'],
  action: ReturnType<typeof setAIAgentListRate>,
): void => {
  const { id, rating } = action.payload
  const findIndex = state.popup.list.findIndex(f => f.id === id)
  if (findIndex >= 0) {
    state.popup.list[findIndex].rating = rating
  }
}
