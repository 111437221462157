import { v4 as uuidv4 } from 'uuid'

export const LSUniqueBrowserKey = 'LSUniqueBrowserKey'

export const getUniqBrowser = () => {
  if (typeof window === 'undefined') {
    return
  }

  let id = window.localStorage.getItem(LSUniqueBrowserKey)

  if (!id) {
    id = uuidv4()
    window.localStorage.setItem(LSUniqueBrowserKey, id)
  }

  return id
}
