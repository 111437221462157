import { FieldError } from 'react-hook-form'

export const handleError = (
  error: FieldError | FieldError[],
  fallbackMessage?: string,
): string | undefined => {
  if (Array.isArray(error)) {
    return error
      .map(e => handleError(e, fallbackMessage))
      .filter(e => !!e)
      .join('\n')
  }
  return error?.message || error?.type === 'required'
    ? error.message || fallbackMessage
    : undefined
}
