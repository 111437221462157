import { differenceInMilliseconds } from 'date-fns'
import { Duration } from '@ads/front-core/interfaces/duration'
import { lng } from '@ads/front-core/locales'

export const generateDuration = (startDate: Date, endDate: Date) => {
  const timeDifference = differenceInMilliseconds(
    new Date(endDate),
    new Date(startDate),
  )

  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24))
  const hours = Math.floor(
    (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  )
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60))

  if (hours === 23 && minutes === 59) {
    return { days: days + 1, hours: 0, minutes: 0 }
  }

  return { days, hours, minutes }
}

export const durationToString = (duration: Duration) =>
  `${lng('calendar:days', { count: duration.days })} ${
    duration.hours ? `${duration.hours} h ` : ''
  }${duration.minutes ? `${duration.minutes} min` : ''}`
