import { useRef, RefObject, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getAIAgentPopupOffset } from 'store/selectors/ai/getAIAgentPopupOffset'
import { setAIAgentPopupOffset } from 'store/reducers/ai/setAIAgentPopupOffset'
import { Offsets } from './interfaces'

export const usePopup = ({
  isVisible,
}: {
  isVisible: boolean
}): {
  popupRef: RefObject<HTMLDivElement>
  headerRef: RefObject<HTMLDivElement>
  initOffset: Offsets
} => {
  const dispatch = useDispatch()

  const popupRef = useRef<HTMLDivElement>()
  const headerRef = useRef<HTMLDivElement>()
  const popupPositionRef = useRef<Offsets>()
  const moveRef = useRef<Offsets>()
  const initOffset = useSelector(getAIAgentPopupOffset)

  useEffect(() => {
    const moveElement = (e: MouseEvent) => {
      e.preventDefault()
      const x = e.clientX - moveRef.current.offsetX
      const y = e.clientY - moveRef.current.offsetY
      popupRef.current.style.left = x + 'px'
      popupRef.current.style.top = y + 'px'

      popupPositionRef.current = {
        offsetX: x,
        offsetY: y,
      }
    }

    const startDragging = (e: MouseEvent) => {
      e.preventDefault()
      moveRef.current = {
        offsetX: e.clientX - popupRef.current.getBoundingClientRect().left,
        offsetY: e.clientY - popupRef.current.getBoundingClientRect().top,
      }
      document.addEventListener('mousemove', moveElement)
    }

    const stopDragging = () => {
      document.removeEventListener('mousemove', moveElement)
      dispatch(setAIAgentPopupOffset(popupPositionRef.current))
    }

    if (headerRef?.current) {
      headerRef.current.addEventListener('mousedown', startDragging)
      headerRef.current.addEventListener('mouseup', stopDragging)
    }

    return () => {
      if (headerRef?.current) {
        headerRef.current.removeEventListener('mousedown', startDragging)
        headerRef.current.removeEventListener('mouseup', stopDragging)
      }
    }
  }, [isVisible, popupRef?.current, headerRef?.current])

  return { popupRef, headerRef, initOffset }
}
