import isHtml from 'is-html'
import { ExtensionFactory } from 'joi'
import { lng } from '@ads/front-core/locales'

export const create = (): ExtensionFactory => joi => ({
  type: 'html',
  base: joi.string(),
  messages: {
    'html.invalidValue': lng('errors:htmlInvalidValue'),
  },
  validate: (value, { error }) => {
    if (!isHtml(value)) {
      return { value, errors: error('html.invalidValue') }
    }
    return { value }
  },
})
