import React, { FC, useEffect, useState, createRef } from 'react'
import styled, { css } from 'styled-components'
import { useDispatch } from 'react-redux'
import { Menu, MenuIcon } from '@ads/front-core/atoms'
import { Dropdown, Flex, IconButton, Tooltip } from '@ads/front-ds'
import { ThumbUp } from '@ads/front-core/Icons/ThumbUp'
import { ThumbDown } from '@ads/front-core/Icons/ThumbDown'
import { ThumbUpSolid } from '@ads/front-core/Icons/ThumbUpSolid'
import { ThumbDownSolid } from '@ads/front-core/Icons/ThumbDownSolid'
import { FlagReport } from '@ads/front-core/Icons/FlagReport'
import { lng } from '@ads/front-core/locales'
import useDropdown from '@ads/front-core/hooks/useDropdown'
import { useClickOutside } from '@ads/front-core/hooks/useEventsOutside'
import zIndex from '@ads/front-core/constants/zIndex'
import { AIMessage } from 'components/AIAgent/AIPopup/interfaces'
import { setAIAgentReportMessage } from 'store/reducers/ai/setAIAgentReportMessage'
import { useRate } from './useRate'

interface IControls {
  message: AIMessage
}

export const Controls: FC<IControls> = ({ message }) => {
  const dispatch = useDispatch()

  const buttonRef = createRef<HTMLDivElement>()
  const menuRef = createRef<HTMLDivElement>()

  const [tooltipState, setTooltipState] = useState<
    'SUBMITTING' | 'SHOW' | 'HIDE'
  >('HIDE')

  const { isSubmitting, rate } = useRate()

  const { isOpen, handleCloseDropdown, handleToggleDropdown } = useDropdown({})

  useClickOutside([buttonRef, menuRef], handleCloseDropdown)

  const handleOnReportMessage = () => {
    dispatch(setAIAgentReportMessage(message))
  }

  useEffect(() => {
    if (isSubmitting) {
      setTooltipState('SUBMITTING')
    } else if (!isSubmitting && tooltipState === 'SUBMITTING') {
      setTooltipState('SHOW')
    }
  }, [isSubmitting, message.rating, tooltipState])

  useEffect(() => {
    let timer = null
    if (tooltipState === 'SHOW') {
      timer = setTimeout(() => setTooltipState('HIDE'), 3000)
    }
    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [tooltipState])

  return (
    <Wrapper>
      <Tooltip
        text={lng('help:thanks')}
        animatable
        placement="top"
        defaultIsOpen
        isOpen={tooltipState === 'SHOW' && message.rating === 'UP'}
      >
        <ThumbButton
          isSelected={message.rating === 'UP'}
          onClick={() =>
            isSubmitting
              ? null
              : rate({
                  messageId: message.id,
                  rating: message.rating === 'UP' ? null : 'UP',
                })
          }
        >
          {message.rating === 'UP' ? <ThumbUpSolid /> : <ThumbUp />}
        </ThumbButton>
      </Tooltip>
      <Tooltip
        text={lng('help:thanks')}
        animatable
        placement="top"
        defaultIsOpen
        isOpen={tooltipState === 'SHOW' && message.rating === 'DOWN'}
      >
        <ThumbButton
          isSelected={message.rating === 'DOWN'}
          onClick={() =>
            isSubmitting
              ? null
              : rate({
                  messageId: message.id,
                  rating: message.rating === 'DOWN' ? null : 'DOWN',
                })
          }
        >
          {message.rating === 'DOWN' ? <ThumbDownSolid /> : <ThumbDown />}
        </ThumbButton>
      </Tooltip>

      <Dropdown
        zIndexMenu={zIndex.DROPDOWN_MENU}
        align="end"
        toggle={
          <div ref={buttonRef}>
            <IconButtonStyled
              name="20-more-vertical"
              variant="bareDark"
              onClick={handleToggleDropdown}
            />
          </div>
        }
        menu={
          <Menu ref={menuRef}>
            <MenuIcon
              Icon={<FlagReport />}
              label={lng('ai:chat.reportForm.title')}
              onClick={handleOnReportMessage}
            />
          </Menu>
        }
        isOpen={isOpen}
      />
    </Wrapper>
  )
}

const Wrapper = styled(Flex)`
  ${p => css`
    flex-direction: row;
    gap: ${p.theme.space.xxs}px;
    height: min-content;
    padding-left: ${p.theme.space.xxs}px;
    padding-top: ${p.theme.space.xxxs}px;
  `}
`

const ThumbButton = styled.button<{ isSelected: boolean }>`
  ${p => css`
    border: none;
    background-color: transparent;
    padding: 0;
    cursor: pointer;
    color: ${p.isSelected ? p.theme.colors.primary50 : p.theme.colors.grey20};
    display: flex;

    :hover {
      color: ${p.theme.colors.primary50};
    }
  `}
`

const IconButtonStyled = styled(IconButton)`
  ${p =>
    css`
      color: ${p.theme.colors.grey20};
    `}
`
