import { createAction } from '@reduxjs/toolkit'
import { AppState } from '../../appState'

export const setAIAgentTyping = createAction<
  AppState['ai']['popup']['isTyping']
>('ai/setAIAgentTyping')

export const setAIAgentTypingReducer = (
  state: AppState['ai'],
  action: ReturnType<typeof setAIAgentTyping>,
): void => {
  state.popup.isTyping = action.payload
}
