import React, { FC, RefObject } from 'react'
import styled, { css } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { Flex, Icon } from '@ads/front-ds'
import { lng } from '@ads/front-core/locales'
import { getAIAgentPopupVisible } from 'store/selectors/ai/getAIAgentPopupVisible'
import { setAIAgentPopupVisible } from 'store/reducers/ai/setAIAgentPopupVisible'
import { setAIAgentPopupOffset } from 'store/reducers/ai/setAIAgentPopupOffset'
import { setAIAgentReportMessage } from 'store/reducers/ai/setAIAgentReportMessage'
import { setAIAgentLimitExceeded } from 'store/reducers/ai/setAIAgentLimitExceeded'
import { AIAvatarHeader } from 'components/AIAgent/Icons/AIAvatarHeader'

interface IAIPopupHeader {
  headerRef: RefObject<HTMLDivElement>
}

export const AIPopupHeader: FC<IAIPopupHeader> = ({ headerRef }) => {
  const dispatch = useDispatch()

  const isVisible = useSelector(getAIAgentPopupVisible)

  const onClickClose = () => {
    dispatch(setAIAgentPopupVisible(false))
    dispatch(setAIAgentPopupOffset(null))
    dispatch(setAIAgentReportMessage(null))
    dispatch(setAIAgentLimitExceeded(false))
  }

  if (!isVisible) {
    return null
  }

  return (
    <Wrapper ref={headerRef}>
      <Flex alignItems="center" gap="xs">
        <AIAvatarHeader />
        {lng('ai:chat.title')}
      </Flex>
      <CloseButton onClick={onClickClose}>
        <Icon name="20-close" />
      </CloseButton>
    </Wrapper>
  )
}

const Wrapper = styled(Flex)`
  ${p => css`
    background-color: ${p.theme.colors.primary100};
    color: ${p.theme.colors.inverted100};
    font-size: 2ppx;
    flex: 0 0 52px;
    padding: 0 14px 0 18px;
    align-items: center;
    justify-content: space-between;
    cursor: grab;
    user-select: none;
  `}
`

const CloseButton = styled.button`
  ${p => css`
    border: 0;
    background: transparent;
    color: ${p.theme.colors.inverted100};
    cursor: pointer;
  `}
`
