import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useWatch } from 'react-hook-form'
import styled, { css } from 'styled-components'
import { marked } from 'marked'
import { ChatbotService } from '@ads/front-core/api'
import { Flex, TextButton, Text } from '@ads/front-ds'
import { getScrollbarStyles } from '@ads/front-core/utils'
import { useAdsForm } from '@ads/front-core/hooks/useAdsForm'
import { lng } from '@ads/front-core/locales'
import TextArea from '@ads/front-core/components/Form/TextArea'
import { getAIAgentReportMessage } from 'store/selectors/ai/getAIAgentReportMessage'
import { setAIAgentReportMessage } from 'store/reducers/ai/setAIAgentReportMessage'
import { setAIAgentReportMessageSending } from 'store/reducers/ai/setAIAgentReportMessageSending'
import { MessageContent } from 'components/AIAgent/AIPopup/AIPopupMessages'
import { ButtonCTA } from './styles'
import { IForm, joiSchema } from './formConfig'
import { Success } from './Success'

export const AIPopupReportMessage: FC = () => {
  const dispatch = useDispatch()

  const aIAgentReportMessage = useSelector(getAIAgentReportMessage)

  const {
    formState: { errors },
    control,
    isSubmitting,
    trigger,
    handleSubmit,
  } = useAdsForm<IForm>({
    joiSchema,
    onSubmit: async (data: IForm) => {
      await ChatbotService.reportChatbotMessage({
        messageId: aIAgentReportMessage.message.id,
        requestBody: {
          reportReason: data.message,
        },
      })
      dispatch(setAIAgentReportMessageSending(true))
    },
  })

  const message = useWatch({ control, name: 'message' })

  const isDisabledButtons = isSubmitting || !message?.length

  const handleOnClose = () => {
    dispatch(setAIAgentReportMessage(null))
  }

  if (aIAgentReportMessage.isSended) {
    return <Success />
  }

  const messageHTML = marked.parse(aIAgentReportMessage.message.response, {
    async: false,
  })

  return (
    <>
      <Wrapper>
        <Text fontSize="l" fontWeight="medium">
          {lng('ai:chat.reportForm.title')}
        </Text>
        <MessageContent variant="ai">
          <div
            dangerouslySetInnerHTML={{
              __html: messageHTML,
            }}
          />
        </MessageContent>
        <TextArea
          label={lng('ai:chat.reportForm.textAreaLabel')}
          name="message"
          error={errors.message}
          control={control}
          trigger={trigger}
        />
      </Wrapper>
      <Buttons>
        <ButtonCTA
          variant="cta"
          isDisabled={isDisabledButtons}
          onClick={handleSubmit}
        >
          {lng('ai:chat.reportForm.submitButton')}
        </ButtonCTA>
        <TextButton onClick={handleOnClose}>{lng('common:cancel')}</TextButton>
      </Buttons>
    </>
  )
}

const Wrapper = styled(Flex)`
  ${p => css`
    flex: 1;
    padding: 40px 44px;
    flex-flow: column;
    gap: ${p.theme.space.xl}px;
    overflow-y: auto;
    ${getScrollbarStyles()}
  `}
`

const Buttons = styled(Flex)`
  ${p => css`
    border-top: ${p.theme.borders.thinGrey20};
    background-color: ${p.theme.colors.grey0};
    padding: ${p.theme.space.s}px;
    flex-flow: column;
    gap: ${p.theme.space.xs}px;
  `}
`
