import React, { FC } from 'react'
import styled, { css } from 'styled-components'
import { isSameDay } from 'date-fns'
import { Flex } from '@ads/front-ds'
import { getScrollbarStyles } from '@ads/front-core/utils'
import { DateSeparator } from './DateSeparator'
import { AILoading } from './AILoading'
import { Message } from './Message'
import { useScrollList } from './useScrollList'

export const AIPopupMessages: FC = () => {
  const { scrollRef, messages, isTyping, sendPrompt } = useScrollList()

  return (
    <Wrapper ref={scrollRef}>
      <ScrollContent>
        {!messages?.length && <div />}
        {messages.map((message, index) => (
          <React.Fragment key={`message-${message.id}`}>
            {index > 0 &&
              !isSameDay(message.date, messages[index - 1].date) && (
                <DateSeparator date={message.date} />
              )}
            <Message variant="user" message={message} sendPrompt={sendPrompt} />
            <Message variant="ai" message={message} sendPrompt={sendPrompt} />
          </React.Fragment>
        ))}
        {isTyping && <AILoading />}
      </ScrollContent>
    </Wrapper>
  )
}

const Wrapper = styled(Flex)`
  flex: 1;
  align-items: flex-end;
  overflow-y: auto;
  scroll-behavior: unset;
`

const ScrollContent = styled(Flex)`
  ${p => css`
    padding: 0 ${p.theme.space.xxs}px;
    flex-flow: column;
    height: min-content;
    max-height: 100%;
    width: 100%;
    ${getScrollbarStyles()}
  `}
`
