import Joi, {
  AnySchema,
  ArraySchema,
  DateSchema,
  NumberSchema,
  ObjectSchema,
  Root,
  StringSchema,
} from 'joi'
import { lng } from '@ads/front-core/locales'
import { TypesFactory } from './CustomTypes/TypesFactory'
import { UrlSchema } from './CustomTypes/url'

export interface CustomJoi extends Root {
  textField(): StringSchema
  adsId(): NumberSchema
  url(): UrlSchema
  draftJS(): ObjectSchema
  domain(): StringSchema
  color(): StringSchema
  nip(): StringSchema
  postalCode(): StringSchema
  arrayField(): ArraySchema
  number<T>(): NumberSchema<T>
  inlineNumberArray(): StringSchema
  phone(): StringSchema
  html(): StringSchema
  utm(): StringSchema
  targetingIAB(): ObjectSchema
}

const customMessages = {
  'any.required': lng('errors:fieldIsRequired'),
  'any.invalid': lng('errors:anyInvalid'),
  'alternatives.types': lng('errors:anyInvalid'),
  'number.base': lng('errors:numberBase'),
  'number.greater': lng('errors:numberGreater'),
  'number.integer': lng('errors:numberInteger'),
  'number.min': lng('errors:numberMin'),
  'number.max': lng('errors:numberMax'),
  'number.unsafe': lng('errors:numberUnsafe'),
  'number.positive': lng('errors:numberPositive'),
  'string.empty': lng('errors:stringEmpty'),
  'string.base': lng('errors:stringBase'),
  'string.domain': lng('errors:stringDomain'),
  'string.email': lng('errors:stringEmail'),
  'string.pattern.base': lng('errors:stringPatternBase'),
  'string.min': lng('errors:stringMin'),
  'string.max': lng('errors:stringMax'),
  'date.base': lng('errors:dateBase'),
  'date.format': lng('errors:dateFormat'),
  'date.min': lng('errors:dateMin'),
  'date.greater': lng('errors:dateGreater'),
  'array.min': lng('errors:arrayMin'),
  'array.includesRequiredUnknowns': lng('errors:arrayIncludesRequiredUnknowns'),
  'array.includes': lng('errors:arrayIncludes'),
  'array.unique': lng('errors:arrayUnique'),
  'html.invalidValue': lng('errors:htmlInvalidValue'),
}

const customJoi: CustomJoi = Joi.defaults(schema =>
  schema.options({ abortEarly: false, messages: customMessages }),
).extend(
  TypesFactory.createTextField(),
  TypesFactory.createAdsId(),
  TypesFactory.createUrl(),
  TypesFactory.createDraftJS(),
  TypesFactory.createDomain(),
  TypesFactory.createColor(),
  TypesFactory.createNip(),
  TypesFactory.createPostalCode(),
  TypesFactory.createArrayField(),
  TypesFactory.createInlineNumberArray(),
  TypesFactory.createPhoneField(),
  TypesFactory.createHTMLField(),
  TypesFactory.createUTMField(),
  TypesFactory.createTargetingIAB(),
)

export type TJoiObject<T> = {
  [K in keyof Required<T>]: JoiOf<T[K]>
}

type JoiOf<T> = T extends string
  ? StringSchema | DateSchema
  : T extends number
  ? NumberSchema
  : T extends Date
  ? DateSchema
  : T extends Array<any>
  ? ArraySchema
  : T extends any
  ? AnySchema
  : T extends Record<string, unknown>
  ? TJoiObject<T>
  : never

export {
  ObjectSchema,
  AnySchema,
  DateSchema,
  ArraySchema,
  ValidationErrorItem,
} from 'joi'

export function JoiObject<T>(schema: TJoiObject<T>): ObjectSchema<T> {
  return Joi.object(schema)
}

export default customJoi
