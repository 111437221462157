import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ChatbotService, RequestChatbotMessage } from '@ads/front-core/api'
import { parseDate, getCaptchaToken } from '@ads/front-core/utils'
import { apiError } from '@ads/front-core/store/sagas/apiErrorSaga'
import { OpenAPI } from '@ads/front-core/api/core/OpenAPI'
import { getAIAgentPopupList } from 'store/selectors/ai/getAIAgentPopupList'
import { getAIAgentTyping } from 'store/selectors/ai/getAIAgentTyping'
import { getAIAgentListCursor } from 'store/selectors/ai/getAIAgentListCursor'
import { getAIAgentSessionId } from 'store/selectors/ai/getAIAgentSessionId'
import { setAIAgentList } from 'store/reducers/ai/setAIAgentList'
import { setAIAgentListResponse } from 'store/reducers/ai/setAIAgentListResponse'
import { addAIAgentListQuestion } from 'store/reducers/ai/addAIAgentListQuestion'
import { setAIAgentTyping } from 'store/reducers/ai/setAIAgentTyping'
import { setAIAgentListCursor } from 'store/reducers/ai/setAIAgentListCursor'
import { setAIAgentSessionId } from 'store/reducers/ai/setAIAgentSessionId'
import { setAIAgentLimitExceeded } from 'store/reducers/ai/setAIAgentLimitExceeded'
import {
  AIMessage,
  AISendPrompt,
  AIListCursor,
  AIErrorFields,
  AIFetchList,
} from './interfaces'
import { AI_LIST_LIMIT } from './constants'

export const useData = (): {
  messages: AIMessage[]
  listCursor: AIListCursor
  isTyping: boolean
  errors: AIErrorFields
  fetchList: AIFetchList
  checkLimitExceeded: () => Promise<void>
  sendPrompt: AISendPrompt
} => {
  const dispatch = useDispatch()
  const [errors, setErrors] = useState<AIErrorFields>()

  const isTyping = useSelector(getAIAgentTyping)
  const messages = useSelector(getAIAgentPopupList)
  const listCursor = useSelector(getAIAgentListCursor)
  const sessionId = useSelector(getAIAgentSessionId)

  const fetchList: AIFetchList = async ({ cursorId }) => {
    if (listCursor?.isLoading) {
      return
    }

    try {
      dispatch(setAIAgentListCursor({ id: cursorId, isLoading: true }))
      const res = await ChatbotService.getChatbotMessages({
        limit: AI_LIST_LIMIT,
        beforeId: cursorId,
        sessionId,
      })
      dispatch(
        setAIAgentList({
          direction: 'old',
          items: res.map(item => ({
            date: parseDate(item.date),
            id: item.id,
            prompt: item.userMessage,
            response: item.botResponse,
            rating: item.rating,
            sessionId: item.sessionId,
          })),
        }),
      )
      if (res?.length > 0) {
        dispatch(setAIAgentSessionId(res[0].sessionId))
      }
    } catch (error) {
      dispatch(apiError({ error }))
    }
    dispatch(setAIAgentListCursor({ id: cursorId, isLoading: false }))
  }

  const sendPrompt: AISendPrompt = async ({ message }) => {
    if (!message?.trim()) {
      return
    }

    setErrors(null)

    dispatch(setAIAgentTyping(true))
    dispatch(
      setAIAgentList({
        direction: 'new',
        items: [
          { id: 'tmp', date: new Date(), prompt: message, response: null },
        ],
      }),
    )

    const captchaToken = await getCaptchaToken()

    const requestBody: RequestChatbotMessage = {
      context: {
        subpage: window.document.title,
      },
      message,
    }

    try {
      const res = await fetch(
        `/api/chatbot/message?sessionId=${encodeURI(
          sessionId || '',
        )}&captchaToken=${encodeURI(captchaToken)}`,
        {
          body: JSON.stringify(requestBody),
          headers: {
            'content-type': 'application/json',
            ...OpenAPI.HEADERS,
          },
          method: 'POST',
        },
      )

      switch (res.status) {
        case 422: {
          const jsonResponse = await res.json()
          setErrors(
            jsonResponse?.reduce((acc, item) => {
              acc[item?.field] = item?.message
              return acc
            }, {}),
          )
          throw new Error(JSON.stringify(jsonResponse))
        }
        case 429:
          dispatch(setAIAgentLimitExceeded(true))
          throw new Error('Too many requests')
      }

      const responses: string[] = []
      const messageId = res.headers.get('x-chat-stream-id')
      const responseSessionId = res.headers.get('x-chat-session-id')

      res.body.pipeTo(
        new WritableStream({
          write(chunk) {
            const chunkDecoded = new TextDecoder('utf-8').decode(chunk)
            const tokens = chunkDecoded.split(/(answer|question)\$/)
            tokens.forEach((token, index) => {
              switch (token) {
                case 'question': {
                  dispatch(
                    addAIAgentListQuestion({
                      id: messageId,
                      question: tokens[index + 1],
                    }),
                  )
                  break
                }
                case 'answer': {
                  responses.push(tokens[index + 1])
                  dispatch(
                    setAIAgentListResponse({
                      oldId: 'tmp',
                      id: messageId,
                      response: responses.join(''),
                      sessionId: responseSessionId,
                    }),
                  )
                  dispatch(setAIAgentTyping(false))
                  break
                }
              }
            })
          },
        }),
      )

      checkLimitExceeded()
    } catch (error) {
      console.error('error', error)
      dispatch(setAIAgentTyping(false))
    }
  }

  const checkLimitExceeded = async () => {
    try {
      const res = await ChatbotService.getChatbotLimit()
      dispatch(setAIAgentLimitExceeded(res.isLimitReached))
    } catch (error) {
      console.error('error', error)
    }
  }

  return {
    messages,
    listCursor,
    isTyping,
    errors,
    fetchList,
    checkLimitExceeded,
    sendPrompt,
  }
}
