import { createAction } from '@reduxjs/toolkit'
import { AppState } from '../../appState'

export const setAIAgentReportMessageSending = createAction<
  AppState['ai']['reportMessage']['isSended']
>('ai/setAIAgentReportMessageSending')

export const setAIAgentReportMessageSendingReducer = (
  state: AppState['ai'],
  action: ReturnType<typeof setAIAgentReportMessageSending>,
): void => {
  state.reportMessage.isSended = action.payload
}
