import { createAction } from '@reduxjs/toolkit'
import { AppState } from '../../appState'

export const setAIAgentPopupVisible = createAction<
  AppState['ai']['popup']['isVisible']
>('ai/setAIAgentPopupVisible')

export const setAIAgentPopupVisibleReducer = (
  state: AppState['ai'],
  action: ReturnType<typeof setAIAgentPopupVisible>,
): void => {
  state.popup.isVisible = action.payload
}
