import React, { FC } from 'react'
import styled, { css } from 'styled-components'
import { useSelector } from 'react-redux'
import { Flex } from '@ads/front-ds'
import zIndex from '@ads/front-core/constants/zIndex'
import { getAIAgentButtonAlign } from 'store/selectors/ai/getAIAgentButtonAlign'
import { getAIAgentPopupVisible } from 'store/selectors/ai/getAIAgentPopupVisible'
import { getAIAgentReportMessage } from 'store/selectors/ai/getAIAgentReportMessage'
import { AIAgentButtonAlign } from 'components/AIAgent/AIAgentButton'
import { AIPopupHeader } from './AIPopupHeader'
import { AIPopupRules } from './AIPopupRules'
import { AIPopupInput } from './AIPopupInput'
import { AIPopupReportMessage } from './AIPopupReportMessage'
import { AIPopupMessages } from './AIPopupMessages'
import { AIPopupLockScreen } from './AIPopupLockScreen'
import { usePopup } from './usePopup'
import { Offsets } from './interfaces'

export const AIPopup: FC = () => {
  const align = useSelector(getAIAgentButtonAlign)
  const isVisible = useSelector(getAIAgentPopupVisible)
  const aIAgentReportMessage = useSelector(getAIAgentReportMessage)

  const { headerRef, popupRef, initOffset } = usePopup({ isVisible })

  if (!isVisible) {
    return null
  }

  return (
    <Wrapper align={align} ref={popupRef} initOffset={initOffset}>
      <AIPopupHeader headerRef={headerRef} />
      {aIAgentReportMessage?.message?.id ? (
        <AIPopupReportMessage />
      ) : (
        <>
          <AIPopupLockScreen />
          <AIPopupMessages />
          <AIPopupRules />
          <AIPopupInput />
        </>
      )}
    </Wrapper>
  )
}

const Wrapper = styled(Flex)<{
  align: AIAgentButtonAlign
  initOffset?: Offsets
}>`
  ${p => css`
    ${p.initOffset?.offsetX > 0 && p.initOffset?.offsetY > 0
      ? css`
          top: ${p.initOffset.offsetY}px;
          left: ${p.initOffset.offsetX}px;
        `
      : css`
          ${p.align === AIAgentButtonAlign.LEFT
            ? css`
                left: ${p.theme.space.m}px;
              `
            : css`
                right: ${p.theme.space.m}px;
              `}
          bottom: 20px;
        `}
    position: fixed;
    max-height: 800px;
    height: 100%;
    width: 100%;
    max-width: 532px;
    z-index: ${zIndex.AIAGENT};
    flex-flow: column;
    box-shadow: 4px -4px 8px 0px #00000040;
    background-color: ${p.theme.colors.inverted100};
    border-radius: 20px 20px 0 0;
    overflow: hidden;

    .dragging {
      cursor: grabbing;
    }
  `}
`
