export enum AIAgentButtonAlign {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export enum AIAgentButtonState {
  HIDDEN = 'HIDDEN',
  EXPANDED = 'EXPANDED',
  COILED = 'COILED',
}
