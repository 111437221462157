import React, { FC, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { Flex, Icon } from '@ads/front-ds'
import zIndex from '@ads/front-core/constants/zIndex'
import { isDev } from '@ads/front-core/utils'
import { lng } from '@ads/front-core/locales'
import { getAIAgentButtonAlign } from 'store/selectors/ai/getAIAgentButtonAlign'
import { getAIAgentButtonState } from 'store/selectors/ai/getAIAgentButtonState'
import { getAIAgentPopupVisible } from 'store/selectors/ai/getAIAgentPopupVisible'
import { isAuthenticated } from '@ads/front-core/store/selectors/isAuthenticated'
import { setAIAgentButton } from 'store/sagas/setAIAgentButton'
import { setAIAgentPopupVisible } from 'store/reducers/ai/setAIAgentPopupVisible'
import { AIAgentButtonAlign, AIAgentButtonState } from './interfaces'
import { AIAgentLSKey } from './constants'
import { AIIconPopup } from './AIIconPopup'

export const AIAgentButton: FC = () => {
  const dispatch = useDispatch()
  const align = useSelector(getAIAgentButtonAlign)
  const state = useSelector(getAIAgentButtonState)
  const isPopupVisible = useSelector(getAIAgentPopupVisible)
  const isLogged = useSelector(isAuthenticated)

  const [isExpandedAnimation, setIsExpandedAnimation] = useState(false)

  const handleHideMessage = (
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e?.stopPropagation()
    window.localStorage.setItem(AIAgentLSKey, '1')
    setIsExpandedAnimation(true)
  }

  const handleShowPopup = () => {
    handleHideMessage()
    dispatch(setAIAgentPopupVisible(true))
  }

  useEffect(() => {
    dispatch(setAIAgentButton())
  }, [isLogged])

  if (
    isPopupVisible ||
    !align ||
    state === AIAgentButtonState.HIDDEN ||
    !isDev()
  ) {
    return null
  }

  return (
    <>
      <Wrapper align={align}>
        <AIIconButton as="button" onClick={handleShowPopup}>
          <AIIconPopup />
        </AIIconButton>
        {state === AIAgentButtonState.EXPANDED && (
          <Message
            as="button"
            align={align}
            onClick={handleShowPopup}
            isExpanded={!isExpandedAnimation}
          >
            {lng(
              isLogged
                ? 'ai:button.messageLogged'
                : 'ai:button.messageNonLogged',
            )}
            <CloseButton as="span" onClick={handleHideMessage}>
              <Icon name="20-close" />
            </CloseButton>
          </Message>
        )}
      </Wrapper>
    </>
  )
}

const Wrapper = styled(Flex)<{ align: AIAgentButtonAlign }>`
  ${p => css`
    ${p.align === AIAgentButtonAlign.LEFT
      ? css`
          left: ${p.theme.space.m}px;
        `
      : css`
          right: ${p.theme.space.m}px;
          flex-flow: row-reverse;
        `}
    position: fixed;
    bottom: 20px;
    z-index: ${zIndex.AIAGENT};
    display: flex;
    gap: ${p.theme.space.xs}px;
  `}
`

const AIIconButton = styled(Flex)`
  align-items: center;
  justify-content: center;
  border: none;
  background-color: #89bef8;
  width: 52px;
  height: 52px;
  border-radius: 26px;
  cursor: pointer;
  box-shadow: 4px 4px 4px 0px #0050ab40;
  overflow: hidden;

  svg {
    left: -6px;
    top: 2px;
    position: relative;
  }
`

const Message = styled(Flex)<{
  isExpanded: boolean
  align: AIAgentButtonAlign
}>`
  ${p => css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: ${p.theme.colors.primary100};
    height: 52px;
    border-radius: 26px;
    cursor: pointer;
    box-shadow: 4px 4px 4px 0px #0050ab40;
    color: ${p.theme.colors.inverted100};
    padding: 0 20px;
    gap: ${p.theme.space.xxs}px;
    line-height: 1em;
    font-size: ${p.theme.fontSizes.l}px;
    white-space: nowrap;

    ${!p.isExpanded &&
    css`
      overflow: hidden;
      animation: hide 0.5s ease-out;
      animation-fill-mode: forwards;
    `}

    @keyframes hide {
      from {
        max-width: 300px;
        opacity: 1;
      }
      to {
        opacity: 0;
        max-width: 0px;
        display: none;
      }
    }

    ::before {
      position: absolute;
      content: url(data:image/svg+xml,%3Csvg%20width%3D%226%22%20height%3D%2212%22%20viewBox%3D%220%200%206%2012%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M0.333375%206L5.83337%2011.5L5.83337%200.5L0.333375%206Z%22%20fill%3D%22%230050AB%22%2F%3E%3C%2Fsvg%3E);
      z-index: 1;
      width: 6px;
      height: 12px;

      ${p.align === AIAgentButtonAlign.LEFT
        ? css`
            left: -5px;
          `
        : css`
            right: -5px;
            transform: rotateZ(180deg);
          `}
    }
  `}
`

const CloseButton = styled(Flex)`
  ${p => css`
    display: flex;
    border: 0;
    background: none;
    padding: 0;
    color: ${p.theme.colors.inverted100};
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  `}
`
