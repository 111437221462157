import React, { FC, useState, useLayoutEffect, useRef } from 'react'

export enum TurnstileVariant {
  NOINTERACTIVE,
  INVISIBLE,
}

const TURNSTILE_KEY: Record<TurnstileVariant, string> = {
  [TurnstileVariant.NOINTERACTIVE]: '0x4AAAAAAAytAL6scn6kUmiM',
  [TurnstileVariant.INVISIBLE]: '0x4AAAAAABDxk2vxOWpx1_06',
}

const TURNSTILE_URL =
  'https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit'
interface ICloudflareTurnstile {
  variant: TurnstileVariant
  onSetToken: (token: string) => void
}

export const CloudflareTurnstile: FC<ICloudflareTurnstile> = ({
  variant,
  onSetToken,
}) => {
  const containerRef = useRef()
  const [isScriptLoading, setIsScriptLoading] = useState(true)

  useLayoutEffect(() => {
    const handleScript = () => {
      setTimeout(() => setIsScriptLoading(false), 1500)
    }

    const scriptExists = document.querySelector(
      `script[src="${TURNSTILE_URL}"]`,
    )
    if (scriptExists) {
      setIsScriptLoading(false)
      return
    }

    const script = document.createElement('script')
    script.type = 'application/javascript'
    script.src = TURNSTILE_URL
    document.body.appendChild(script)
    script.addEventListener('load', handleScript)

    return () => {
      script.removeEventListener('load', handleScript)
    }
  }, [])

  useLayoutEffect(() => {
    if (!isScriptLoading && containerRef?.current) {
      window.turnstileId = window.turnstile.render(containerRef.current, {
        sitekey: TURNSTILE_KEY[variant],
        callback: onSetToken,
        'error-callback': e => console.log('error-callback', e),
      })
    }
    return () => {
      if (window.turnstileId) {
        window.turnstile.remove(window.turnstileId)
      }
    }
  }, [isScriptLoading, containerRef?.current])

  return <div ref={containerRef} />
}
