import { ExtensionFactory } from 'joi'
import { lng } from '@ads/front-core/locales'

export const create = (): ExtensionFactory => joi => ({
  type: 'arrayField',
  base: joi.array(),
  messages: {
    'arrayField.required': lng('errors:fieldIsRequired'),
    'arrayField.min': lng('errors:arrayMin'),
    'arrayField.includesRequiredUnknowns': lng(
      'errors:arrayIncludesRequiredUnknowns',
    ),
  },
  validate: (value, { error }) => {
    if (!value?.length) {
      return { value, errors: error('arrayField.required') }
    }

    return { value }
  },
})
