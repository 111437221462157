import { ExtensionFactory } from 'joi'
import { lng } from '@ads/front-core/locales'

export const create = (): ExtensionFactory => joi => ({
  type: 'color',
  base: joi.string(),
  messages: {
    'color.format': lng('errors:colorFormat'),
  },
  validate: (value, { error }) => {
    if (!/(^#[0-9A-Fa-f]{3}$)|(^#[0-9A-Fa-f]{6}$)/.test(value)) {
      return { value, errors: error('color.format') }
    }
    return { value }
  },
})
