import React, { FC } from 'react'

interface IThumbDownSolid {
  color?: string
}

export const ThumbDownSolid: FC<IThumbDownSolid> = ({ color }) => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.833 10.5H19.1663V0.5H15.833M12.4997 0.5H4.99967C4.30801 0.5 3.71634 0.916666 3.46634 1.51667L0.949674 7.39167C0.874674 7.58333 0.833008 7.78333 0.833008 8V9.66667C0.833008 10.1087 1.0086 10.5326 1.32116 10.8452C1.63372 11.1577 2.05765 11.3333 2.49967 11.3333H7.75801L6.96634 15.1417C6.94967 15.225 6.94134 15.3083 6.94134 15.4C6.94134 15.75 7.08301 16.0583 7.30801 16.2833L8.19134 17.1667L13.6747 11.675C13.983 11.375 14.1663 10.9583 14.1663 10.5V2.16667C14.1663 1.24167 13.4163 0.5 12.4997 0.5Z"
      fill={color || 'currentColor'}
    />
  </svg>
)
