import isHtml from 'is-html'
import { ExtensionFactory } from 'joi'
import { lng } from '@ads/front-core/locales'

export const TEXT_FIELD_MAX_LENGTH = 200

export const create = (): ExtensionFactory => joi => ({
  type: 'textField',
  base: joi.string(),
  messages: {
    'textField.max': lng('errors:textMaxSigns', {
      length: TEXT_FIELD_MAX_LENGTH,
    }),
    'textField.html': lng('errors:textFieldFormat'),
  },
  validate: (value, { error }) => {
    if (value.length > TEXT_FIELD_MAX_LENGTH) {
      return { value, errors: error('textField.max') }
    }
    if (isHtml(value)) {
      return { value, errors: error('textField.html') }
    }
    return { value }
  },
})
