import React, { FC } from 'react'
import styled, { css } from 'styled-components'
import { Flex } from '@ads/front-ds'

export const AILoading: FC = () => {
  return (
    <Wrapper>
      <Loader>
        <Dots />
      </Loader>
    </Wrapper>
  )
}

const Wrapper = styled(Flex)`
  ${p => css`
    padding: 0 ${p.theme.space.xxs}px ${p.theme.space.xs}px
      ${p.theme.space.xxs}px;
    width: min-content;
  `}
`

const Loader = styled(Flex)`
  ${p => css`
    padding: ${p.theme.space.xxs}px;
    border-radius: 0 20px 20px 20px;
    background-color: ${p.theme.colors.grey0};
  `}
`

const Dots = styled(Flex)`
  ${p => css`
    width: 40px;
    height: ${p.theme.space.m}px;
    aspect-ratio: 2;
    --loaderdot: no-repeat
      radial-gradient(circle closest-side, #d9d9d9 50%, #0000);
    background: var(--loaderdot) 0% 50%, var(--loaderdot) 50% 50%,
      var(--loaderdot) 100% 50%;
    background-size: calc(100% / 3) 40%;
    animation: jump 1s infinite linear;
    @keyframes jump {
      20% {
        background-position: 0% 20%, 50% 50%, 100% 50%;
      }
      40% {
        background-position: 0% 70%, 50% 20%, 100% 50%;
      }
      60% {
        background-position: 0% 50%, 50% 70%, 100% 20%;
      }
      80% {
        background-position: 0% 50%, 50% 50%, 100% 70%;
      }
    }
  `}
`
