import { createAction } from '@reduxjs/toolkit'
import { AppState } from '../../appState'

type AddAIAgentListQuestion = {
  id: AppState['ai']['popup']['list'][number]['id']
  question: string
}

export const addAIAgentListQuestion = createAction<AddAIAgentListQuestion>(
  'ai/addAIAgentListQuestion',
)

export const addAIAgentListQuestionReducer = (
  state: AppState['ai'],
  action: ReturnType<typeof addAIAgentListQuestion>,
): void => {
  const { id, question } = action.payload
  const findIndex = state.popup.list.findIndex(f => f.id === id)
  if (findIndex >= 0) {
    state.popup.list[findIndex].questions = [
      ...(state.popup.list[findIndex].questions || []),
      question,
    ]
  }
}
