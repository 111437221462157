import React, { FC } from 'react'
import styled, { css } from 'styled-components'
import { Box } from '@ads/front-ds'
import { AIMessage, AISendPrompt } from 'components/AIAgent/AIPopup/interfaces'

interface IQuestion {
  question: AIMessage['questions'][number]
  sendPrompt: AISendPrompt
}

export const Question: FC<IQuestion> = ({ question, sendPrompt }) => {
  const handleOnClick = () => sendPrompt({ message: question })

  return (
    <QuestionWrapper>
      <QuestionButton onClick={handleOnClick}>{question}</QuestionButton>
    </QuestionWrapper>
  )
}

const QuestionWrapper = styled(Box)``

const QuestionButton = styled.div`
  ${p => css`
    display: inline-block;
    background-color: #cce1f9;
    padding: ${p.theme.space.s}px;
    border-radius: 20px;
    cursor: pointer;

    &:hover {
      background-color: ${p.theme.colors.primary05};
    }
  `}
`
