import { createAction } from '@reduxjs/toolkit'
import { AppState } from '../../appState'

export const setAIAgentButtonState = createAction<
  AppState['ai']['button']['state']
>('ai/setAIAgentButtonState')

export const setAIAgentButtonStateReducer = (
  state: AppState['ai'],
  action: ReturnType<typeof setAIAgentButtonState>,
): void => {
  state.button.state = action.payload
}
