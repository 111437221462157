import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { ChatbotService, ChatMessageRating } from '@ads/front-core/api'
import { apiError } from '@ads/front-core/store/sagas/apiErrorSaga'
import { setAIAgentListRate } from 'store/reducers/ai/setAIAgentListRate'

export const useRate = (): {
  isSubmitting: boolean
  rate: ({
    messageId,
    rating,
  }: {
    messageId: string
    rating: ChatMessageRating | null
  }) => Promise<void>
} => {
  const dispatch = useDispatch()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const rate = async ({
    messageId,
    rating,
  }: {
    messageId: string
    rating: ChatMessageRating | null
  }) => {
    setIsSubmitting(true)

    try {
      dispatch(setAIAgentListRate({ id: messageId, rating }))
      await ChatbotService.setChatbotMessageRating({
        messageId,
        requestBody: { rating },
      })
    } catch (error) {
      dispatch(apiError({ error }))
    }

    setIsSubmitting(false)
  }

  return {
    isSubmitting,
    rate,
  }
}
