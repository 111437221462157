import { createAction } from '@reduxjs/toolkit'
import { AppState } from '../../appState'

type SetAIAgentList = Pick<
  AppState['ai']['popup']['list'][number],
  'id' | 'response' | 'sessionId'
> & { oldId?: string }

export const setAIAgentListResponse = createAction<SetAIAgentList>(
  'ai/setAIAgentListResponse',
)

export const setAIAgentListResponseReducer = (
  state: AppState['ai'],
  action: ReturnType<typeof setAIAgentListResponse>,
): void => {
  const { oldId, id, response, sessionId } = action.payload

  if (oldId) {
    const findIndex = state.popup.list.findIndex(f => f.id === oldId)
    if (findIndex >= 0) {
      state.popup.list[findIndex].id = id
      state.popup.list[findIndex].response = response
      state.popup.list[findIndex].sessionId = sessionId
      return
    }
  }

  const findIndex = state.popup.list.findIndex(f => f.id === id)
  if (findIndex >= 0) {
    state.popup.list[findIndex].response = response
    state.popup.list[findIndex].sessionId = sessionId
  }
}
