import { ExtensionFactory } from 'joi'
import { lng } from '@ads/front-core/locales'

export const create = (): ExtensionFactory => joi => ({
  type: 'nip',
  base: joi.string(),
  messages: {
    'nip.format': lng('errors:nipFormat'),
  },
  validate: (value, { error }) => {
    const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7]
    const nip = String(value).replace(/[\s-]/g, '')

    if (nip.length === 10 && parseInt(nip, 10) > 0) {
      let sum = 0
      for (let i = 0; i < 9; i++) {
        sum += Number(nip[i]) * weights[i]
      }
      if (sum % 11 !== Number(nip[9])) {
        return {
          value,
          errors: error('nip.format'),
        }
      }
      return {
        value,
      }
    }
    return { value, errors: error('nip.format') }
  },
})
