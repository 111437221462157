import React, { FC } from 'react'

interface IFlagReport {
  color?: string
}

export const FlagReport: FC<IFlagReport> = ({ color }) => (
  <svg
    width="13"
    height="15"
    viewBox="0 0 13 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.166992 14.5V0.333374H7.66699L8.00033 2.00004H12.667V10.3334H6.83366L6.50033 8.66671H1.83366V14.5H0.166992ZM8.20866 8.66671H11.0003V3.66671H6.62533L6.29199 2.00004H1.83366V7.00004H7.87533L8.20866 8.66671Z"
      fill={color || 'currentColor'}
    />
  </svg>
)
