import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { ExternalTextLink } from '@ads/front-core/atoms'
import { Flex, Text } from '@ads/front-ds'
import { Trans } from '@ads/front-core/locales'
import { getAIAgentAbuseReport } from 'store/selectors/ai/getAIAgentAbuseReport'
import { SadAvatar } from './SadAvatar'
import { AI_RULES_URL } from 'components/AIAgent/AIPopup/constants'

export const AIPopupLockScreen: FC = () => {
  const isAbuseReport = useSelector(getAIAgentAbuseReport)

  if (!isAbuseReport) {
    return null
  }

  return (
    <Wrapper>
      <SadAvatar />
      <Text textAlign="center">
        <Trans
          i18nKey="ai:chat.abuseMessage"
          components={{
            textLink: <ExternalTextLink href={AI_RULES_URL} target="_blank" />,
          }}
        />
      </Text>
    </Wrapper>
  )
}

const Wrapper = styled(Flex)`
  ${p => css`
    flex: 1;
    align-items: center;
    justify-content: center;
    padding: 0 44px 40px 44px;
    flex-flow: column;
    gap: ${p.theme.space.xl}px;
    position: absolute;
    top: 52px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: #f8f8f9cc;
    backdrop-filter: blur(8px);
  `}
`
