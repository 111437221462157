/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Error } from '../models/Error'
import type { RequestChatbotMessage } from '../models/RequestChatbotMessage'
import type { RequestReportChatbotMessage } from '../models/RequestReportChatbotMessage'
import type { RequestSetChatbotMessageRating } from '../models/RequestSetChatbotMessageRating'
import type { ResponseChatbotLimit } from '../models/ResponseChatbotLimit'
import type { ResponseChatbotMessageList } from '../models/ResponseChatbotMessageList'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class ChatbotService {
  /**
   * Wysyła wiadomość do chatbota
   * @returns string Odpowiedź od chatbota(streaming markdown formatted response)
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static sendChatbotMessage({
    captchaToken,
    requestBody,
    sessionId,
  }: {
    /**
     * token do validacji captchy
     */
    captchaToken: string
    /**
     * Treść i kontekst wiadomości
     */
    requestBody: RequestChatbotMessage
    /**
     * id sesji chatbota
     */
    sessionId?: string
  }): CancelablePromise<string | Error> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/chatbot/message',
      query: {
        sessionId: sessionId,
        captchaToken: captchaToken,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Ocenia odpowiedź od chatbota
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static setChatbotMessageRating({
    messageId,
    requestBody,
  }: {
    /**
     * identyfikator wiadomości
     */
    messageId: string
    /**
     * Ocena
     */
    requestBody: RequestSetChatbotMessageRating
  }): CancelablePromise<Error> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/chatbot/message/{messageId}/rating',
      path: {
        messageId: messageId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Zapisuje zgłoszenie nieodpowiedniej treści
   * @returns Error unexpected error
   * @throws ApiError
   */
  public static reportChatbotMessage({
    messageId,
    requestBody,
  }: {
    /**
     * identyfikator wiadomości
     */
    messageId: string
    /**
     * Treść zgłoszenia
     */
    requestBody: RequestReportChatbotMessage
  }): CancelablePromise<Error> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/chatbot/message/{messageId}/report',
      path: {
        messageId: messageId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Pobiera historię konwersacji
   * @returns ResponseChatbotMessageList Lista wiadomości
   * @throws ApiError
   */
  public static getChatbotMessages({
    limit,
    sessionId,
    beforeId,
  }: {
    /**
     * liczba elementów do pobrania
     */
    limit: number
    /**
     * id sesji chatbota
     */
    sessionId?: string
    /**
     * numer id dla cursora (pobieranie danych z mniejszym id)
     */
    beforeId?: string
  }): CancelablePromise<ResponseChatbotMessageList> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/chatbot/messages',
      query: {
        sessionId: sessionId,
        beforeId: beforeId,
        limit: limit,
      },
    })
  }

  /**
   * Zwraca informację czy limit requestów został juz osiągnięty
   * @returns ResponseChatbotLimit Informacja o osiągnięciu limitu
   * @throws ApiError
   */
  public static getChatbotLimit(): CancelablePromise<ResponseChatbotLimit> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/chatbot/limit',
    })
  }
}
