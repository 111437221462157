import { useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

export const useWindowScroll = (): number => {
  const [scrollY, setScrollY] = useState(0)
  const [scrollStop, setScrollStop] = useState(0)

  const listener = () => {
    const offset = document.body.getBoundingClientRect()
    setScrollY(offset.top)
  }

  const listenerCallback = useDebouncedCallback(listener, 500)

  const onScroll = () => listenerCallback()

  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  })

  useEffect(() => {
    const timer = setTimeout(() => {
      setScrollStop(scrollY)
    }, 100)
    return () => clearTimeout(timer)
  }, [scrollY])

  return scrollStop
}
