import { RefObject, useEffect } from 'react'

type Events = string | string[]

interface UseEventsOutsideOptions {
  disabled?: boolean
}

export const useEventsOutside = (
  ref: RefObject<HTMLElement> | RefObject<HTMLElement>[],
  handler: (event: Event) => void,
  events: Events,
  options: UseEventsOutsideOptions = { disabled: false },
): void => {
  useEffect(() => {
    if (options.disabled) return
    const listener = (event: Event) => {
      if (Array.isArray(ref)) {
        if (
          event.target &&
          ref.some(r => r.current?.contains(event.target as Node))
        ) {
          return
        }
      } else {
        if (event.target && ref.current?.contains(event.target as Node)) {
          return
        }
      }
      handler(event)
    }
    if (typeof events === 'object') {
      events.forEach(event => document.addEventListener(event, listener))
    } else {
      document.addEventListener(events, listener)
    }
    return () => {
      if (typeof events === 'object') {
        events.forEach(event => document.removeEventListener(event, listener))
      } else {
        document.removeEventListener(events, listener)
      }
    }
  }, [ref, handler, events, options.disabled])
}

const CLICK_OUTSIDE_EVENTS = ['mousedown', 'touchstart']

export const useClickOutside = (
  ref: RefObject<HTMLElement> | RefObject<HTMLElement>[],
  handler: () => void,
  options?: UseEventsOutsideOptions,
): void => {
  if (!ref || !handler) {
    return
  }
  useEventsOutside(ref, handler, CLICK_OUTSIDE_EVENTS, options)
}
