import { createAction } from '@reduxjs/toolkit'
import { AppState } from '../../appState'

export const setAIAgentPopupOffset = createAction<
  AppState['ai']['popup']['offset']
>('ai/setAIAgentPopupOffset')

export const setAIAgentPopupOffsetReducer = (
  state: AppState['ai'],
  action: ReturnType<typeof setAIAgentPopupOffset>,
): void => {
  state.popup.offset = action.payload
}
