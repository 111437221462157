import { createAction } from '@reduxjs/toolkit'
import { AppState } from '../../appState'

export const setAIAgentLimitExceeded = createAction<
  AppState['ai']['popup']['isLimitExceeded']
>('ai/setAIAgentLimitExceeded')

export const setAIAgentLimitExceededReducer = (
  state: AppState['ai'],
  action: ReturnType<typeof setAIAgentLimitExceeded>,
): void => {
  state.popup.isLimitExceeded = action.payload
}
