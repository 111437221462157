import React, { FC } from 'react'

interface IThumbDown {
  color?: string
}

export const ThumbDown: FC<IThumbDown> = ({ color }) => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8335 10.5V0.5H19.1668V10.5H15.8335ZM12.5002 0.5C12.9422 0.5 13.3661 0.675595 13.6787 0.988155C13.9912 1.30072 14.1668 1.72464 14.1668 2.16667V10.5C14.1668 10.9583 13.9835 11.375 13.6752 11.675L8.19183 17.1667L7.3085 16.2833C7.0835 16.0583 6.94183 15.75 6.94183 15.4L6.96683 15.1417L7.7585 11.3333H2.50016C1.57516 11.3333 0.833496 10.5833 0.833496 9.66667V8C0.833496 7.78333 0.875163 7.58333 0.950163 7.39167L3.46683 1.51667C3.71683 0.916666 4.3085 0.5 5.00016 0.5H12.5002ZM12.5002 2.16667H4.97516L2.50016 8V9.66667H9.81683L8.87516 14.1L12.5002 10.475V2.16667Z"
      fill={color || 'currentColor'}
    />
  </svg>
)
